import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import {NgxSpinnerService} from 'ngx-spinner';
import moment from 'moment';

declare var $: any;

export class BaseComponent {

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService) {
    }

    setToken(token: string) {
        localStorage.setItem('token', token);
    }

    getBaseURL() {
        return environment.base_url;
    }

    switchLanguage(language: string) {
        if (language === 'pt') {
            language = 'pt';
        } else if (language === 'en') {
            language = 'en';
        } else if (language === 'es') {
            language = 'es';
        }
        this.translate.use(language);
    }

    handlerConfirm() {
        $('#modalNoteResume').modal('show');
    }

    showModal(idModal) {
        $('#' + idModal).modal('show');
    }

    closeModal(idModal) {
        $('#' + idModal).modal('hide');
    }

    showMessage(t: string, m: string, type: any = 'warning') {
        Swal.fire({
            title: t,
            html: m,
            icon: type,
            confirmButtonColor: '#032E58',
        });
    }

    confirmMessage(t: string, m: string, callback: any = null) {
        Swal.fire({
            title: t,
            text: m,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            confirmButtonColor: '#032E58',
            cancelButtonColor: '#dc3545'
        }).then((result) => {
            if (!this.isNullOrUndefined(result.value) && result.value === true) {
                if (callback != null) {
                    callback();
                }
            }
        });
    }

    onError(error) {
        this.loading.hide();
        if (error.status === 401) {
            this.router.navigate(['/authenticate']);
            return;
        }

        // const e = JSON.parse(error['error']);

        if (error.status === 400) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), error.error.message, 'warning');
            return;
        }

        if (error.status === 500) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgError'), 'warning');
            return;
        }
    }
    onErrorRest(error) {
        const e = JSON.parse(error);
        this.loading.hide();
        if (e.statusCode === 401) {
            this.router.navigate(['/authenticate']);
            return;
        }

        if (e.statusCode === 400) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), e.message, 'warning');
            return;
        }

        if (e.statusCode === 500) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgError'), 'warning');
            return;
        }
    }

    isNullOrUndefined(value) {
        return typeof value === 'undefined' || value == null || value.length === 0;
    }

    validateField(obj) {
        let strError = ``;
        obj.map((e) => {
            if (this.isNullOrUndefined(e.value)) {
                strError += `${e.text}`;
            }
        });
        return strError;
    }

    handlerFlagImage(value) {
        switch (value) {
            case 'Chile':
                return 'chile.svg';
            case 'Peru':
                return 'peru.svg';
            case 'Bolívia':
                return 'bolivia.svg';
            case 'Venezuela':
                return 'venezuela.svg';
            case 'Argentina':
                return 'argentina.svg';
            case 'México':
                return 'mexico.svg';
            case 'Equador':
                return 'equador.svg';
            case 'Guatemala':
                return 'guatemala.svg';
            case 'Brazil':
                return 'brazil.svg';
            case 'Colômbia':
                return 'colombia.svg';
            case 'Uruguai':
                return 'uruguay.svg';
            case 'Paraguay':
                return 'paraguay.svg';
        }
    }

    handlerStepBar(value) {

        switch (value) {
            case 1:
                return 'width: 0%';
            case 2:
                return 'width: 25%';
            case 3:
                return 'width: 50%';
            case 4:
                return 'width: 75%';
            case 5:
                return 'width: 100%';
        }
    }

    setDatePicker(id: string, callback, date = 30, end?) {
        $('.' + id).daterangepicker({
            startDate: moment().subtract(date, 'days'),
            endDate: end == null ? moment() : moment().add(end, 'days'),
            cancelButtonClasses: 'btn-danger',
            ranges: {
                Hoje: [moment(), moment()],
                Ontem: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '7 Dias': [moment().subtract(6, 'days'), moment()],
                '30 Dias': [moment().subtract(29, 'days'), moment()]
            },
            locale: {
                customRangeLabel: 'Personalizar',
                format: 'DD/MM/YYYY',
                separator: ' - ',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                daysOfWeek: [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ],
                monthNames: [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ],
                firstDay: 1
            }
        }, callback);
    }

    toggleLoader(b: boolean) {
        if (!b) {
            $('#loaderBox').fadeOut();
        } else {
            $('#loaderBox').fadeIn();
        }
    }

    isEMailValid(strEmail) {
        const str = strEmail;
        const exp = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
        const testResult = exp.test(str);
        if (!testResult) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgEmail'), 'warning');
        }
        return testResult;
    }

    onLogout() {
        this.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningExit'), () => {
            const p = window.location.pathname.split('/');
            const virtualPath = p.length >= 3 ? '/' + p[1] : '';
            const redirectUrl = 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' +
                window.location.origin + virtualPath + '/logout';
            window.location.href = redirectUrl;
        });
    }

    getStatusNameByKey(s: string) {
        if (!this.isNullOrUndefined(s)) {
            return this.translate.instant('statusNames.' + s);
        }
    }

    getMangerTypeByKey(k: string) {
        if (!this.isNullOrUndefined(k)) {
            return this.translate.instant('managersName.' + k);
        }
    }
}
