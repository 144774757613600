<div class="body">
    <!-- HEADER FUNCTIONS -->
    <div class="header flex-wrap">
        <!--HEADER TITLE-->
        <h1 class="header-title">
            <svg-icon src="assets/svg/productblue.svg"></svg-icon>
            {{'produtos'|translate}}
        </h1>
        <!--FUNCTIONS-->

        <!--IMPORT-->
        <div class="button_section">

            <a class="btn btn-gray" type="button" href="assets/import/model_import_product_GFAM.xlsx">
                <svg-icon src="assets/svg/excel.svg"></svg-icon>
                <span class="ml-1"> {{'model'|translate}}</span>
            </a>

            <label for="import" class="btn btn-gray mb-0">
                <svg-icon src="assets/svg/upload.svg"></svg-icon>
                <span class="ml-1"> {{'importar'|translate}}</span>
                <input class="d-none" type="file" id="import" name="import" (change)="imporExcel($event)"
                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
            </label>

            <a href="javascript:void(0)" class="btn btn-gray" type="button" (click)="getExcel()">
                <svg-icon src="assets/svg/download.svg"></svg-icon>
                <span class="ml-1">{{'exportar'|translate}}</span>
            </a>

            <a href="javascript:void(0)"
               (click)="actionNew()" *ngIf=actionUserProfile()
               class="btn btn-primary"
               type="button">
                <svg-icon src="assets/svg/add.svg"></svg-icon>
                <span class="ml-1">{{'adicionar'|translate}}</span>
            </a>
        </div>


    </div>
    <!-- KEYWORD -->
    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
        <!-- SEARCH -->
        <div class="input-material" style="flex-grow: 9">
            <input id="search" maxlength="100" [(ngModel)]="filter.keyword" class="form-control" type="text" required/>
            <label for="search">
                <svg-icon src="assets/svg/search.svg"></svg-icon>
                {{'busca'|translate}}
            </label>
        </div>
        <div class="divider d-none d-md-block">&nbsp;</div>

        <!--COUNTRY-->
        <div class="input-material flex-grow-1">
            <ng-multiselect-dropdown
                    [settings]="dropSettings"
                    [(ngModel)]="filterCountries"
                    [data]="countriesUser"
                    [placeholder]="'buscar' | translate"
                    (ngModelChange)="getLinesByCountries();"
            >
            </ng-multiselect-dropdown>
            <label class="mb-0 mr-3 disabled">{{'pais' | translate}}</label>
        </div>

        <!-- LINE -->
        <div class="input-material flex-grow-1">
            <select id="linear" class="form-control" name="type" [(ngModel)]="filter.line">
                <option selected
                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                <option [value]="l.id" *ngFor="let l of lines">{{l.name}} </option>
            </select>
            <label for="linear" class="mb-0 mr-3">{{'linha'|translate}}</label>
        </div>
        <!-- PERIOD -->
        <!--        <div class="input-material" style="flex-grow:3">-->
        <!--            <input id="startImputMaterial" class="form-control js-daterangepicker" type="text"-->
        <!--                   required/>-->
        <!--            <label for="startImputMaterial">{{'periodo'|translate}}</label>-->
        <!--        </div>-->
        <!-- FILTER -->
        <a href="javascript:void(0)" (click)="onFilter()"
           class="btn btn-gray flex-grow-1"
           type="button">
            <span>{{'filtrar'|translate}}</span>
        </a>
    </div>
    <!--GRID-->
    <div class="table-responsive">
        <table class="table">
            <!--TABLE HEAD-->
            <thead>
            <tr>
                <th scope="col">{{'produto'|translate}}</th>
                <th scope="col">{{'linha'|translate}}</th>
                <th scope="col">{{'managersName.medical-manager'|translate}}</th>
                <th scope="col">{{'managersName.district-manager'|translate}}</th>
                <th scope="col">{{'managersName.analyst-marketing'|translate}}</th>
                <th scope="col">{{'managersName.product-manager'|translate}}</th>
                <th scope="col">{{'managersName.marketing-manager'|translate}}</th>
                <th scope="col"></th>
            </tr>
            </thead>
            <!--TABLE BODY-->
            <tbody>
            <tr *ngFor="let p of product?.results
                    | paginate
                      : {
                          id: 'product',
                          itemsPerPage: this.product.pageSize,
                          currentPage: this.product.currentPage,
                          totalItems: this.product.totalCount
                        }">
                <td>{{p?.name}}</td>
                <td>{{p?.productLine?.name}}</td>
                <td><span *ngFor="let m of getType(p?.managers,'medical-manager')"> {{m?.user?.name}}</span></td>
                <td><span *ngFor="let m of getType(p?.managers,'district-manager')"> {{m?.user?.name}}</span></td>
                <td><span *ngFor="let m of getType(p?.managers,'analyst-marketing')"> {{m?.user?.name}}</span></td>
                <td><span *ngFor="let m of getType(p?.managers,'product-manager')"> {{m?.user?.name}}</span></td>
                <td><span *ngFor="let m of getType(p?.managers,'marketing-manager')"> {{m?.user?.name}}</span></td>
                <!--BUTTON-->
                <td width="130">
                    <button (click)="actionEdit(p)" *ngIf=actionUserProfile()>
                        <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                    </button>
                    <button (click)="actionDelete(p.id)" *ngIf=actionUserProfile()>
                        <svg-icon src="assets/svg/delete.svg"></svg-icon>
                    </button>
                    <button *ngIf="validateAdd()" (click)="ShowModalEdit(p)">
                        <svg-icon class="gray_svg" src="assets/svg/person.svg"></svg-icon>
                    </button>
                </td>
            </tr>
            <tr *ngIf="isNullOrUndefined(product)">
                <td colspan="7" class="text-center py-4">Nenhum registro encontrado!</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="text-right">
        <app-pagination-custom
                [id]="'product'"
                (pageChange)="pageChanged($event)"
        ></app-pagination-custom>
    </div>
    <!--MODAL NEW PRODUCT-->
    <div class="modal-custom modal fade" id="modalAddProduct" data-keyboard="false"
         data-backdrop="static" role="dialog"
         aria-labelledby="modalNoteResumeLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <!--MODAL PAGE-->
            <div class="modal-content">
                <!-- MODAL HEADER -->
                <div class="modal_header">
                    <h3 class="header-title">
                        {{isNullOrUndefined(this.model.id) ? 'Novo Produto' : 'Editar Produto'}}
                    </h3>
                    <a href="javascript:$('#modalAddProduct').modal('hide'); void('');"
                       class="btn"
                       (click)="onFilter()"
                       type="button">
                        <svg-icon src="assets/svg/dismiss.svg"></svg-icon>
                    </a>
                </div>
                <!-- MODAL BODY -->
                <div class="modal-body modal-body--small">
                    <!-- DIV NAME AND LINE -->
                    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
                        <div class="input-material" style="flex-grow:3">
                            <input id="productName" [(ngModel)]="this.model.name" maxlength="100" class="form-control"
                                   type="text"
                                   required/>
                            <label for="productName">{{'nome'|translate}}</label>
                        </div>
                        <div class="input-material flex-grow-1">
                            <select id="startLinha1M" [(ngModel)]="this.productLineSelected" class="form-control"
                                    name="type">
                                <option selected
                                        [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                                <option [value]="l.id" *ngFor="let l of lines">{{l.name}} </option>
                            </select>
                            <label for="startLinha1M" class="mb-0 mr-3">{{'linha'|translate}}</label>
                        </div>
                    </div>
                    <!-- COUNTRY -->
                    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
                        <div class="input-material flex-grow-1">
                            <ng-multiselect-dropdown
                                    [settings]="dropSettings"
                                    [(ngModel)]="selectedCountries"
                                    [data]="countriesUser"
                                    [placeholder]="'buscar' | translate">
                            </ng-multiselect-dropdown>
                            <label class="mb-0 mr-3 disabled">{{'pais' | translate}}</label>
                        </div>
                    </div>

                    <!--                    <div class="divider_line mt-3 mb-5"></div>-->
                    <!--                    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px"-->
                    <!--                         *ngIf="!isNullOrUndefined(this.model.id)">-->

                    <!--                        <div class="input-material flex-grow-1">-->
                    <!--                            <select id="product-manager-input" [(ngModel)]="selectProfile" class="form-control"-->
                    <!--                                    name="type" (change)="changeProfile()">-->
                    <!--                                <option selected-->
                    <!--                                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>-->
                    <!--                                <option value="medical-manager">Gerente Médico</option>-->
                    <!--                                <option value="district-manager">Gerente de Distrito</option>-->
                    <!--                                <option value="analyst-marketing">Analista de Marketing</option>-->
                    <!--                                <option value="product-manager">Gerente de Produto</option>-->
                    <!--                                <option value="marketing-manager">Gerente de Marketing</option>-->
                    <!--                            </select>-->
                    <!--                            <label for="product-manager-input">{{'gestao'|translate}}</label>-->
                    <!--                        </div>-->

                    <!--                        <div class="input-material flex-grow-1">-->
                    <!--                            <select id="manager-user" class="form-control"-->
                    <!--                                    name="type" [(ngModel)]="selectManager">-->
                    <!--                                <option selected-->
                    <!--                                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>-->
                    <!--                                <option *ngFor="let u of byProfile" [ngValue]="u">{{u.email}} </option>-->

                    <!--                            </select>-->
                    <!--                            <label for="manager-user">{{'usuarios'|translate}}</label>-->
                    <!--                        </div>-->
                    <!--                        &lt;!&ndash; BTN USER &ndash;&gt;-->
                    <!--                        <a href="javascript:void(0)"-->
                    <!--                           (click)="addManager()"-->
                    <!--                           class="btn btn-primary"-->
                    <!--                           type="button">-->
                    <!--                            <svg-icon src="assets/svg/add.svg"></svg-icon>-->
                    <!--                            <span class="ml-1">{{'adicionar'|translate}}</span>-->
                    <!--                        </a>-->
                    <!--                    </div>-->
                    <!--                    &lt;!&ndash; MANAGERS &ndash;&gt;-->
                    <!--                    <div class="row align-items-end">-->
                    <!--                        <div class="col-md-4 col-12 mt-3 pr-0" *ngFor="let m of model.managers">-->
                    <!--                            <div class="d-flex justify-content-between align-items-center">-->
                    <!--                                <b>{{getMangerTypeByKey(m.type)}}</b>-->
                    <!--                                <svg-icon (click)="removeManagers(m.id)" src="assets/svg/redcross.svg"></svg-icon>-->
                    <!--                            </div>-->
                    <!--                            <br>-->
                    <!--                            <div class="input-material col-12">-->
                    <!--                                <input id="manager-name" [value]="m.user.name" class="form-control valid " type="text"-->
                    <!--                                       disabled-->
                    <!--                                       required/>-->
                    <!--                                <label for="manager-name">Nome</label>-->
                    <!--                            </div>-->
                    <!--                            <br>-->
                    <!--                            <div class="input-material">-->
                    <!--                                <input id="manager-email" [value]="m.user.email" class="form-control valid" type="text"-->
                    <!--                                       disabled-->
                    <!--                                       required/>-->
                    <!--                                <label for="manager-email">E-mail</label>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->


                    <!-- BUTTON SAVE -->
                    <button
                            class="btn btn-block btn-warning mt-3" (click)="actionSave()"
                            *ngIf=actionUserProfile()>{{'salvar'|translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!--MODAL USER MANAGEMENT-->
    <div class="modal-custom modal fade" id="modalUserManag" data-keyboard="false"
         data-backdrop="static" role="dialog"
         aria-labelledby="modalNoteResumeLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <!--MODAL PAGE-->
            <div class="modal-content">
                <!-- MODAL HEADER -->
                <div class="modal_header">
                    <h3 class="header-title">
                        GERENCIAMENTO DE USUÁRIO
                    </h3>
                    <a href="javascript:$('#modalUserManag').modal('hide'); void('');"
                       class="btn"
                       (click)="onFilter()"
                       type="button">
                        <svg-icon src="assets/svg/dismiss.svg"></svg-icon>
                    </a>
                </div>
                <!-- MODAL BODY -->
                <div class="modal-body modal-body--small">
                    <!--                    &lt;!&ndash; DIV NAME AND LINE &ndash;&gt;-->
                    <!--                    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">-->
                    <!--                        <div class="input-material" style="flex-grow:3">-->
                    <!--                            <input id="productNameM" [(ngModel)]="this.model.name" maxlength="100" class="form-control"-->
                    <!--                                   type="text"-->
                    <!--                                   required/>-->
                    <!--                            <label for="productNameM">{{'nome'|translate}}</label>-->
                    <!--                        </div>-->
                    <!--                        <div class="input-material flex-grow-1">-->
                    <!--                            <select id="startLinha1" [(ngModel)]="this.productLineSelected" class="form-control"-->
                    <!--                                    name="type">-->
                    <!--                                <option selected-->
                    <!--                                        [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>-->
                    <!--                                <option [value]="l.id" *ngFor="let l of byProfile">{{l.name}} </option>-->
                    <!--                            </select>-->
                    <!--                            <label for="startLinha1" class="mb-0 mr-3">{{'perfil'|translate}}</label>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <!--                    &lt;!&ndash; COUNTRY &ndash;&gt;-->
                    <!--                    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">-->
                    <!--                        <div class="input-material flex-grow-1">-->
                    <!--                            <ng-multiselect-dropdown-->
                    <!--                                    [settings]="dropSettings"-->
                    <!--                                    [(ngModel)]="selectedCountries"-->
                    <!--                                    [data]="countriesUser"-->
                    <!--                                    [placeholder]="'buscar' | translate">-->
                    <!--                            </ng-multiselect-dropdown>-->
                    <!--                            <label class="mb-0 mr-3 disabled">{{'pais' | translate}}</label>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                    <!--                    <div class="divider_line mt-3 mb-5"></div>-->
                    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
                        <!--                         *ngIf="!isNullOrUndefined(this.model.id)">-->

                        <div class="input-material flex-grow-1">
                            <select id="product-manager-inputs" [(ngModel)]="selectProfile" class="form-control"
                                    name="type" (change)="changeProfile()">
                                <option selected
                                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                                <option value="medical-manager">Gerente Médico</option>
                                <option value="district-manager">Gerente de Distrito</option>
                                <option value="analyst-marketing">Analista de Marketing</option>
                                <option value="product-manager">Gerente de Produto</option>
                                <option value="marketing-manager">Gerente de Marketing</option>
                            </select>
                            <label for="product-manager-inputs">{{'gestao'|translate}}</label>
                        </div>

                        <div class="input-material flex-grow-1">
                            <select id="manager-users" class="form-control"
                                    (click)="countryByManagerUser(selectManager.id)"
                                    name="type" [(ngModel)]="selectManager">
                                <option selected
                                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                                <option *ngFor="let u of byProfile" [ngValue]="u">{{u.email}}</option>

                            </select>
                            <label for="manager-users">{{'usuarios'|translate}}</label>
                        </div>
                        <!-- COUNTRY -->
                        <div class="input-material flex-grow-1">
                            <select id="startLinha1" [(ngModel)]="selectCountryCreateManager" class="form-control"
                                    name="type">
                                <option selected
                                        [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                                <option [value]="l.id" *ngFor="let l of countriesManager">{{l.name}} </option>
                            </select>
                            <label for="startLinha1" class="mb-0 mr-3">{{'pais'|translate}}</label>
                        </div>
                        <!-- BTN USER -->
                        <a href="javascript:void(0)"
                           (click)="addManager()"
                           class="btn btn-primary"
                           type="button">
                            <svg-icon src="assets/svg/add.svg"></svg-icon>
                            <span class="ml-1">{{'adicionar'|translate}}</span>
                        </a>
                    </div>

                    <div class="divider_line mt-3 mb-5"></div>
                    <div class="table-responsive table_inside">
                        <table class="table">
                            <!--TABLE HEAD-->
                            <thead>
                            <tr>
                                <th scope="col">{{'nome'|translate}}</th>
                                <th scope="col">{{'pais'|translate}}</th>
                                <th scope="col">{{'perfil'|translate}}</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <!--TABLE BODY-->
                            <tbody>
                            <tr *ngFor="let i of model?.managers">
                                <td>{{i?.user?.name}}</td>
                                <td>{{i?.country?.name}}</td>
                                <td>{{getMangerTypeByKey(i?.type)}}</td>
                                <td>
                                    <button (click)="removeManagers(i.id)">
                                        <svg-icon src="assets/svg/delete.svg"></svg-icon>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="false">
                                <td colspan="4" class="text-center py-4">Nenhum registro encontrado!</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- BUTTON SAVE -->
                    <!--                    <button-->
                    <!--                            class="btn btn-block btn-warning mt-3" (click)="actionSave()"-->
                    <!--                            *ngIf=actionUserProfile()>{{'salvar'|translate}}-->
                    <!--                    </button>-->
                </div>
            </div>
        </div>
    </div>
</div>

