<pagination-template #p="paginationApi"
                     [id]="id"
                     (pageChange)="pageChange.emit($event)"
                     (pageBoundsCorrection)="pageBoundsCorrection.emit($event)">

    <div class="pagination-custom">
        <div class="pagination-custom_item pagination-custom_item-prev" [class.disabled]="p.isFirstPage()">
            <a href="javascript:void(0)" (click)="p.previous()">
                <svg-icon [svgStyle]="{'transform':'rotate(180deg)'}" src="assets/svg/more.svg"></svg-icon>
            </a>
        </div>

        <div *ngFor="let page of p.pages" class="pagination-custom_item"
             [class.current]="p.getCurrent() === page.value">
            <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                <span>{{ page.label }}</span>
            </a>
            <div *ngIf="p.getCurrent() === page.value">
                <span>{{ page.label }}</span>
            </div>
        </div>

        <div class="pagination-custom_item pagination-custom_item-next" [class.disabled]="p.isLastPage()">
            <a href="javascript:void(0)" (click)="p.next()">
                <svg-icon src="assets/svg/more.svg"></svg-icon>
            </a>
        </div>
    </div>


</pagination-template>
