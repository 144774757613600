import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../base/base.component';
import {
    Budget,
    CitiesQuery,
    Competence,
    GetProductLinesGQL,
    Product,
    GetProductLinesQuery,
    ProductsByLinesGQL,
    ProductsByLinesQuery,
    SearchBudgetAll,
    SearchBudgetsQuery,
    SearchCompetenceAll,
    SearchCompetenceAllGQL,
    SearchCompetenceAllQuery,
    Country,
    GetCountriesQuery,
    GetCountriesGQL,
    RemoveCompetenceGQL,
    User,
    GetCountriesByUserGQL,
    GetCountriesByUserQuery,
    ProductLine,
    SearchProductLine,
    SearchProductLinesByCountryQuery, SearchProductLinesByCountryGQL, GetProductsByLineQuery, GetProductsByLineGQL
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {environment} from '../../../environments/environment';
import {UserService} from '../../service/user.service';
import moment from 'moment';

declare var $: any;

@Component({
    selector: 'app-competence',
    templateUrl: './competence.component.html',
    styleUrls: ['./competence.component.scss']
})
export class CompetenceComponent extends BaseComponent implements OnInit {
    user = new User();
    title = 'Competencia';
    icon = 'competence';

    searchResultQuery: QueryRef<SearchCompetenceAllQuery>;
    filterFront: SearchCompetenceAll = new SearchCompetenceAll();
    filter: SearchCompetenceAll = new SearchCompetenceAll();
    result: Array<Competence>;

    productQuery: QueryRef<ProductsByLinesQuery>;
    product: Array<Product>;

    productsByLineQuery: QueryRef<GetProductsByLineQuery>;
    products: Array<Product> = [];

    // lineQuery: QueryRef<GetProductLinesQuery>;
    // line: any;

    lines: Array<ProductLine> = [];
    linesQuery: QueryRef<SearchProductLinesByCountryQuery>;

    filterL = new SearchProductLine();

    currentPage = 1;
    labels: any = {
        previousLabel: ' ',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    countries: Array<Country>;
    countryQuery: QueryRef<GetCountriesQuery>;

    countriesUser: Array<Country>;
    countryUserQuery: QueryRef<GetCountriesByUserQuery>;

    filterCountries: Country[] = [];

    dropSettings: any = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Marcar todos',
        unSelectAllText: 'Desmarcar todos',
        itemsShowLimit: 3,
        allowSearchFilter: true
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public searchCompetenceAllGQL: SearchCompetenceAllGQL,
                private productsByLinesGQL: ProductsByLinesGQL,
                private getProductLinesGQL: GetProductLinesGQL,
                private getProductsByLineGQL: GetProductsByLineGQL,
                private removeCompetence: RemoveCompetenceGQL,
                public searchProductLinesByCountryGQL: SearchProductLinesByCountryGQL,
                public getCountriesGQL: GetCountriesGQL,
                public getCountriesByUserGQL: GetCountriesByUserGQL,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, loading, translate);
        this.filterFront.start = moment().startOf('day').subtract(30, 'days').utcOffset(0, true).format('DD/MM/YYYY');
        this.filterFront.end = moment().endOf('day').utcOffset(0, true).format('DD/MM/YYYY');
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                if (!this.isNullOrUndefined(this.user.id)) {
                    // this.getCountries();
                    // this.getLines();
                    // this.getProducts();
                    this.setFilter();
                    this.getCountriesByUser();
                    this.searchResultQuery = this.searchCompetenceAllGQL.watch({filter: this.filter});
                    this.searchResultQuery.valueChanges.subscribe(({data}) => {
                        this.result = data.searchCompetenceAll as Array<Competence>;
                    });
                }
            }, error: err => super.onError(err)
        });


    }

    setFilter() {
        const start = moment(this.filterFront.start, 'DD/MM/YYYY');
        const end = moment(this.filterFront.end, 'DD/MM/YYYY');
        this.filter.start = moment(start).startOf('day').subtract(30, 'days').utcOffset(0, true).format();
        this.filter.end = moment(end).endOf('day').utcOffset(0, true).format();
    }

    pageChanged(event) {
        this.currentPage = event;
        this.onFilter();
    }

    onFilter() {
        this.setFilter();
        this.filter.countries = this.filterCountries.map(e => e.id);
        this.searchResultQuery.refetch({filter: this.filter});
    }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            this.countries = data.countries as Array<Country>;
        });
    }

    getCountriesByUser() {
        this.countryUserQuery = this.getCountriesByUserGQL.watch();
        this.countryUserQuery.valueChanges.subscribe(({data}) => {
            this.countriesUser = data.countriesByUser as Array<Country>;
        });
    }

    getLinesByCountries() {
        this.filterL.countries = this.filterCountries.map(e => e.id);
        this.linesQuery = this.searchProductLinesByCountryGQL.watch({id: this.filterL.countries});
        this.linesQuery.valueChanges.subscribe(({data}) => {
            this.lines = data.searchProductLinesByCountry as Array<ProductLine>;
        });
    }

    getProductsByLine() {
        this.productsByLineQuery = this.getProductsByLineGQL.watch({
            lines: this.filter.line
        });
        this.productsByLineQuery.valueChanges.subscribe(({data}) => {
            this.products = data.productsByLines as Array<Product>;
        });
        // .pipe(map(result => result.data.productsByLines as Array<Product>));
    }

    // getLines() {
    //     this.filter.countries = this.filterCountries.map(e => e.id);
    //     this.lineQuery = this.getProductLinesGQL.watch();
    //     this.lineQuery.valueChanges.subscribe(({data}) => {
    //         this.line = data.productLines;
    //     });
    // }

    getProducts() {
        this.filter.countries = this.filterCountries.map(e => e.id);
        this.productQuery = this.productsByLinesGQL.watch({lines: []});
        this.productQuery.valueChanges.subscribe(({data}) => {
            this.product = data.productsByLines as Array<Product>;
        });
    }

    onProducts() {
        this.productQuery.refetch({lines: this.filter.line === undefined ? [] : [this.filter.line]});
    }

    actionDelete(idProduct) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningCompetenceDelete'), () => {
            this.removeCompetence.mutate({id: idProduct}).subscribe((result) => {
                this.searchResultQuery.refetch();
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningCompetenceDeleteSuccess'), 'success');
            });
        });
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'medical-manager':
                return false;
            case 'district-manager':
                return false;
            case 'analyst-marketing':
                return false;
            case 'product-manager':
                return false;
            case 'marketing-manager':
                return false;
            case 'admin':
                return true;
            case 'admin-local':
                return true;
            case 'consult':
                return false;
        }
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        const start = moment(this.filter.start).format('YYYY-MM-DD');
        const end = moment(this.filter.end).format('YYYY-MM-DD');
        let url = `${environment.base_url}/competences/search-competence/excel?token=${token}`;
        if (!super.isNullOrUndefined(this.filter.keyword)) {
            url += ('&keyword=' + this.filter.keyword);
        }
        if (!super.isNullOrUndefined(this.filter.line)) {
            url += ('&line=' + this.filter.line);
        }
        if (!super.isNullOrUndefined(this.filter.product)) {
            url += ('&product=' + this.filter.product);
        }
        if (!super.isNullOrUndefined(this.filter.status)) {
            url += ('&status=' + this.filter.status);
        }
        if (!super.isNullOrUndefined(start)) {
            url += ('&start=' + start);
        }
        if (!super.isNullOrUndefined(end)) {
            url += ('&end=' + end);
        }
        this.filter.countries = this.filterCountries.map(e => e.id);
        if (!super.isNullOrUndefined(this.filter.countries) || this.filter.countries.length > 0) {
            url += ('&countries=' + this.filter.countries.join('|'));
        }
        window.open(encodeURI(url));
    }

}
