<div class="body">
    <div class="header">
        <h1 class="header-title">
            <svg-icon src="assets/svg/{{this.icon}}.svg"></svg-icon>
           {{'consultamedica'|translate}}
        </h1>
        <a href="javascript:void(0)"
           class="btn btn-gray"
            (click)="getExcel()">
            <svg-icon src="assets/svg/download.svg"></svg-icon>
            <span>{{'exportarexcel' | translate}}</span>
        </a>
    </div>
    <!--HEADERS-->
    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
        <!--KEYWORD-->
        <div class="input-material" style="flex-grow: 9">
            <input id="search" class="form-control" type="text" required [(ngModel)]="filter.keyword"/>
            <label for="search">
                <svg-icon src="assets/svg/search.svg"></svg-icon>
                {{'busca' | translate}}
            </label>
        </div>
        <div class="divider d-none d-md-block">&nbsp;</div>
        <!--COUNTRY-->
        <div class="input-material flex-grow-1">
            <ng-multiselect-dropdown
                    [settings]="dropSettings"
                    [(ngModel)]="filterCountries"
                    [data]="countriesUser"
                    [placeholder]="'buscar' | translate"
                    (ngModelChange)="getLinesByCountries();"
            >
            </ng-multiselect-dropdown>
            <label class="mb-0 mr-3 disabled">{{'pais' | translate}}</label>
        </div>
        <!--LINE-->
        <div class="input-material flex-grow-1">
            <select id="line" class="form-control" name="type" [(ngModel)]="filter.line"
                    (ngModelChange)="getProductsByLine(); filter.product = null;">
                <option selected
                        [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                <option *ngFor="let c of lines" [ngValue]="c.id">{{c.name}}</option>

            </select>
            <label for="product" class="mb-0 mr-3"> {{'linha' | translate}}</label>
        </div>
        <!--PRODUCT-->
        <div class="input-material flex-grow-1">
            <select id="product" class="form-control" name="type" [(ngModel)]="filter.product">
                <option selected [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                <option *ngFor="let c of products" [ngValue]="c.id">{{c.name}}</option>

            </select>
            <label for="product" class="mb-0 mr-3">{{'produto' | translate}}</label>
        </div>
        <!--STATUS-->
        <div class="input-material flex-grow-1">
            <select id="status" class="form-control" name="type" [(ngModel)]="filter.status">
                <option selected [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                <option [ngValue]="'pending'">{{'status.pending' | translate}}</option>
                <option [ngValue]="'answered'">{{'status.answered' | translate}}</option>
            </select>
            <label for="status" class="mb-0 mr-3">Status</label>
        </div>
        <!--PERIOD-->
        <div class="input-material flex-grow-1">
            <input id="start" class="form-control" type="text"
                   [(ngModel)]="this.filterFront.start" mask="d0/M0/0000" [dropSpecialCharacters]="false" required/>
            <label for="start">{{'start' | translate}}</label>
        </div>

        <div class="input-material flex-grow-1">
            <input id="end" class="form-control" type="text"
                   [(ngModel)]="this.filterFront.end" mask="d0/M0/0000" [dropSpecialCharacters]="false" required/>
            <label for="end">{{'end' | translate}}</label>
        </div>

        <a href="javascript:void(0)"
           class="btn btn-gray flex-grow-1"
           type="button" (click)="onFilter()">
            <span>{{'filtrar' | translate}}</span>
        </a>
    </div>
    <!--GRID-->
    <div class="table-responsive">
        <table class="table">
            <thead>
            <tr>
                <th scope="col">{{'linha' | translate}}</th>
                <th scope="col">{{'produto' | translate}}</th>
                <th scope="col">{{'usuario' | translate}}</th>
                <th scope="col">{{'datacadastro' | translate}}</th>
                <th ><p class="text-center m-0">{{'pais' | translate}}</p></th>
                <th ><p class="text-center m-0">{{'like' | translate}}</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="consults.length === 0">
                <td colspan="7">
                    <p class="text-center py-5">{{'nenhumresultado' | translate}}</p>
                </td>
            </tr>
            <tr *ngFor="let x of consults">
                <td>
                    <div class="d-flex align-items-center" style="gap: 18px">
                        <div style="width: 4px; height: 42px;" [ngClass]="{
                                'bg-warning' : (x.consult.status === 'pending'),
                                'bg-success' : (x.consult.status === 'answered')
                            }"></div>
                        {{x.consult.product?.productLine?.name}}
                    </div>
                </td>
                <td>{{x.consult.product?.name}}</td>
                <td>{{x.consult.createdBy?.name}}</td>
                <td>{{x.consult.createdAt | date: 'dd/MM/yyyy'}}</td>
                <td class="d-flex flex-column align-items-center justify-content-center">
                    <img src="{{x.consult.country?.flag}}" width="25">{{x.consult.country.name}}
                </td>
                <td>
                    <div class="d-flex justify-content-center align-items-center"
                         style="gap:5px;">
                        <div class="d-flex align-items-center pr-1" style="gap: 7px">
                            <svg-icon src="assets/svg/like.svg"></svg-icon>
                            {{x.likes}}
                        </div>
                        <div class="d-flex align-items-center pl-1" style="gap: 7px">
                            <svg-icon src="assets/svg/dislike.svg"></svg-icon>
                            {{x.dislikes}}
                        </div>
                    </div>
                </td>
                <td>{{getStatusNameByKey(x.consult?.status)}}</td>
                <td>
                    <button class="mr-4" *ngIf="x.consult?.status === 'pending'" routerLink="{{this.router.url}}/{{x.consult?.id}}">
                        <svg-icon src="assets/svg/opened.svg"></svg-icon>
                    </button>
                    <button class="mr-4" *ngIf="x.consult?.status === 'answered'" routerLink="{{this.router.url}}/{{x.consult?.id}}">
                        <svg-icon src="assets/svg/answered.svg"></svg-icon>
                    </button>
                    <button (click)="actionDelete(x.consult?.id)" *ngIf=actionUserProfile()>
                        <svg-icon src="assets/svg/delete.svg"></svg-icon>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

