<div class="body">
    <div class="header">
        <h1 class="header-title">
            <svg-icon class="cursor-pointer" routerLink="/budget-event" src="assets/svg/arrow.svg"></svg-icon>
            {{'verbaevento'|translate}}
        </h1>
    </div>
    <div class="card align-items-center flex-nowrap card-status" style="margin-bottom: 38px; min-height: 150px">
        <div class="bar" [ngClass]="{'bg-success' : (budget.status === 'approved'),
            'bg-warning' : (budget.status === 'pending-product' || budget.status === 'disapproved-product')}">
            <div class="bar-item" [ngClass]="{'bg-warning' : (
            budget.status === 'disapproved'
            || budget.status === 'revision'
            || budget.status === 'pending'
            || budget.status === 'pending-product'
            || budget.status === 'disapproved-product'
            ),
            'concluded' : (budget.status === 'approved')}">
                <p class="number">1</p>
                <svg-icon src="assets/svg/check.svg"></svg-icon>
                <p class="text_sub"> {{'district-product-marketing'|translate}}</p>
            </div>
            <div class="bar-item" [ngClass]="{'bg-warning' : (
            budget.status === 'pending-product'
            || budget.status === 'disapproved-product'
            ),
            'concluded' : (budget.status === 'approved')}">
                <p class="number">2</p>
                <svg-icon src="assets/svg/check.svg"></svg-icon>
                <p class="text_sub">{{'marketing-product' |translate}}</p>
            </div>
        </div>
        <ng-container
                *ngIf="budget.status !== 'approved' && budget.status !== 'disapproved' && budget.status !== 'disapproved-product' && budget.status !== 'revision'">
            <div class="divider"></div>
            <dl class="flex-grow-1">
                <dt class="card-status-label">{{'responder'|translate}}</dt>
                <dd class="d-flex flex-wrap" style="gap: 22px">
                    <button (click)="actionChangeStatus('approved')" class="btn btn-success flex-grow-1"
                            [disabled]="!actionUserProfileStatus()">{{'aprovar'|translate}}</button>
                    <button (click)="actionModal('#modalReview')" class="btn btn-warning flex-grow-1"
                            [disabled]="!actionUserProfileStatus()"
                            *ngIf="budget.status === 'pending'">{{'revisar'|translate}}</button>
                    <button (click)="actionModal('#modalReject')" class="btn btn-danger flex-grow-1"
                            [disabled]="!actionUserProfileStatus()">{{'reprovar'|translate}}</button>
                </dd>
            </dl>
        </ng-container>
        <!--<dl *ngIf="false" class="d-flex align-items-center flex-grow-1" style="gap: 10px">
            <dt class="body-label label-secondary font-weight-normal mb-2">Reprovado por:</dt>
            <dd class="d-flex flex-wrap" style="gap: 23px;">
                <img src="assets/images/profile.jpg" alt="photo" class="card-disapproved-img">
                <div class="d-flex flex-column" style="line-height: 1.3;">
                    <span class="card-disapproved-name">Carlos Coibra</span>
                    <span class="card-disapproved-sector">Marketing</span>
                    <span class="card-disapproved-date">25/10/2021</span>
                </div>
            </dd>
        </dl>-->
    </div>

    <ng-container *ngIf="budget.status === 'disapproved' || budget.status === 'revision'">
        <ng-container *ngFor="let h of budget.history; let i = index;">
            <div class="card card-disapproved flex-md-row" *ngIf="budget.history.length === (i+1)">
                <dl class="card-disapproved-col flex-grow-1">
                    <dt class="body-label label-secondary font-weight-normal mb-2">{{'reprovadopor'|translate}}</dt>
                    <dd class="d-flex flex-wrap" style="gap: 23px">
                        <!--<img class="card-disapproved-img" src="assets/images/profile.jpg" alt="photo">-->
                        <img class="card-disapproved-img" alt="photo"
                             src="{{!budget.createdBy?.photo
                             ? 'assets/svg/person.svg'
                             : budget.createdBy?.photo}}"/>
                        <div class="d-flex flex-column" style="line-height: 1.3;">
                            <span class="card-disapproved-name">{{h.createdBy?.name}}</span>
                            <span class="card-disapproved-sector">{{h.createdBy?.profile| translate}}</span>
                            <span class="card-disapproved-date">{{h.updatedAt | date: 'dd/MM/yyyy'}}</span>
                        </div>
                    </dd>
                </dl>
                <div class="divider"></div>
                <dl class="flex-grow-1">
                    <dt class="body-label mb-2">{{'justificativa'|translate}}</dt>
                    <dd class="body-paragraph">
                        {{budget.response}}
                    </dd>
                </dl>
            </div>
        </ng-container>
    </ng-container>

    <div class="card-info" style="margin-bottom: 39px" [ngClass]="{
        'bg-warning' : (budget.status === 'pending' || budget.status === 'pending-product'),
        'bg-info' : (budget.status === 'revision'),
        'bg-success' : (budget.status === 'approved'),
        'bg-danger' : (budget.status === 'disapproved' || budget.status === 'disapproved-product')
    }">
        <div class="d-flex flex-wrap" style="gap: 13px;">
            <div class="d-flex align-items-center flex-wrap" style="flex-grow: 3;gap: 23px">
                <img class="card-info-img" alt="photo"
                     src="{{!budget.createdBy?.photo
                     ? 'assets/svg/person.svg'
                     : budget.createdBy?.photo}}"/>
                <div class="d-flex flex-column">
                    <span class="card-info-name">{{budget.createdBy?.name}}</span>
                    <span class="card-info-unity">{{budget.createdBy?.profile | translate}}</span>
                </div>
            </div>
            <dl class="flex-grow-0" style="gap: 0">
                <dt>{{'criadoem'|translate}}</dt>
                <dd>{{budget.createdAt | date: 'dd/MM/yyyy'}}</dd>
            </dl>
            <div class="card-divider">&nbsp;</div>
            <dl class="flex-grow-1" style="gap: 0">
                <dt>Status</dt>
                <dd>{{getStatusNameByKey(budget.status)}}</dd>
            </dl>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6">
            <h2 class="body-title">{{budget.description}}</h2>
            <!--<p class="body-paragraph mb-5">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut
                labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et
                ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
            </p>-->
        </div>
        <div class="col-sm-6">
            <div class="card card-event bg-gray-variant flex-row text-white mb-4 align-items-center">
                <span class="event-label">
                    <svg-icon src="assets/svg/date.svg"></svg-icon>
                    {{'dataevento'|translate}}
                </span>
                <span class="event-value">
                    <!--<span>25</span>-->
                    <!--                    <strong>{{budget.date | date: 'fullDate'}}</strong>-->
                    {{ budget.date | date: 'fullDate':'UTC'}}
                </span>
            </div>
            <div class="card card-local flex-row mb-5">
                <dl class="flex-grow-1">
                    <dt class="body-label label-tertiary mb-2">Local</dt>
                    <dd class="d-flex" style="gap: 16px">
                        <!--<svg-icon [svgStyle]="{'width':'30px'}" src="assets/images/country/brazil.svg"></svg-icon>-->
                        <img style="width: 30px; height: 30px;" [src]="budget.city?.country?.flag"
                             alt="{{budget.city?.country.name}}"/>
                        <p class="body-label label-tertiary mb-0" style="line-height: 1;">
                            {{budget.city?.country.name}}
                            <br>
                            <span>{{budget.city?.name}}</span>
                        </p>
                    </dd>
                </dl>
                <dl class="flex-grow-1 text-right">
                    <dt class="body-label label-tertiary mb-2">{{'valoracao'|translate}}</dt>
                    <dd class="body-value value-secondary"><span>{{budget.shareValue | currency : '$'}}</span></dd>
                </dl>
            </div>

        </div>
    </div>
    <div class="row" style="row-gap: 25px">
        <div class="col-sm-6" [ngClass]="{'col-sm-12' : groupedArrayByProductLine.length === 0}">
            <div class="card" style="gap: 32px">
                <!--                <dl class="">-->
                <!--                    <dt class="body-label label-tertiary">{{'unidadenegocio'|translate}}</dt>-->
                <!--                    <dd class="body-value">{{budget.unity?.name}}</dd>-->
                <!--                </dl>-->
                <!--                <div class="divider">&nbsp;</div>-->
                <div class="d-flex">
                    <dl class="flex-grow-1">
                        <dt class="body-label label-tertiary">{{'instituicao'|translate}}</dt>
                        <dd class="body-value">{{budget.institution}}</dd>
                    </dl>
                    <dl class="flex-grow-1">
                        <dt class="body-label label-tertiary">{{'numerodoutores'|translate}}</dt>
                        <dd class="body-value">{{budget.quantity}}</dd>
                    </dl>
                </div>
                <div class="divider">&nbsp;</div>
                <dl>
                    <dt class="body-label label-tertiary mb-2">{{'especialidade'|translate}}</dt>
                    <dd class="d-flex flex-wrap" style="gap: 10px">
                        <span class="tags" *ngFor="let e of budget.speciality">
                            {{e.name}}
                        </span>
                    </dd>
                </dl>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="card" style="padding: 20px 33px;" *ngIf="groupedArrayByProductLine.length > 0">
                <ng-container *ngFor="let p of groupedArrayByProductLine">
                    <div style="margin: 15px 0">
                        <dl class="mb-2">
                            <dt class="body-label label-tertiary">{{'nomelinha'|translate}}</dt>
                            <dd class="body-value">
                                {{p[0]}}
                            </dd>
                        </dl>
                        <dl>
                            <dt class="body-label label-tertiary mb-2">{{'nomeproduto'|translate}}</dt>
                            <dd class="d-flex flex-wrap" style="gap: 10px">
                                <span class="tags" *ngFor="let s of p[1]">
                                    {{s.name}}
                                </span>
                            </dd>
                        </dl>
                    </div>
                    <div class="divider">&nbsp;</div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalReview" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">

        <div class="modal-content">
            <div class="modal_header">
                <p class="header-title">
                    {{'justificativarevisao'|translate}}
                </p>

                <a href="javascript:$('#modalReview').modal('hide'); void('');"
                   class="btn "
                   type="button">
                    <svg-icon src="assets/svg/dismiss.svg"></svg-icon>
                </a>

            </div>
            <div class="modal-body modal-body--small">
                <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
                    <div class="input-material" style="flex-grow:3">
                        <textarea id="response" class="form-control" rows="4"
                                  [(ngModel)]="budgetUpdateStatus.response"></textarea>
                        <label for="response">{{'escrevajustificativa'|translate}}</label>
                    </div>
                </div>
                <button class="btn btn-block btn-warning mt-3"
                        (click)="actionChangeStatus('revision')">{{'salvar'|translate}}</button>
            </div>

        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalReject" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">

        <div class="modal-content">
            <div class="modal_header">
                <p class="header-title">
                    {{'justificativarevisao'|translate}}
                </p>

                <a href="javascript:$('#modalReject').modal('hide'); void('');"
                   class="btn "
                   type="button">
                    <svg-icon src="assets/svg/dismiss.svg"></svg-icon>
                </a>

            </div>
            <div class="modal-body modal-body--small">
                <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
                    <div class="input-material" style="flex-grow:3">
                        <textarea id="start" class="form-control" rows="4"
                                  [(ngModel)]="budgetUpdateStatus.response"></textarea>
                        <label for="start">{{'escrevajustificativa'|translate}}</label>
                    </div>
                </div>
                <button class="btn btn-block btn-warning mt-3"
                        (click)="actionChangeStatus(budget.status === 'pending-product' ? 'disapproved-product' : 'disapproved')">
                    Salvar
                </button>
            </div>

        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalApproved" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">

        <div class="modal-content">


            <div class="modal-body d-flex flex-column align-items-center modal-body--small">
                <a href="javascript:$('#modalApproved').modal('hide'); void('');"
                   class="btn ml-auto"
                   type="button">
                    <svg-icon src="assets/svg/dismiss.svg"></svg-icon>
                </a>
                <svg-icon class="mb-3" src="assets/svg/approved.svg"></svg-icon>
                <p class="modal-text">
                    {{'propostaaprovada'|translate}}
                </p>
            </div>


        </div>
    </div>
</div>
