<nav id="sidebar">
    <ul class="list-unstyled components navbar-nav" id="accordionExample">

        <li class="header__mobile d-lg-none">
            <div class="header">
                <div class="d-flex justify-content-around text-white">
                    <font class="header_font text-white">Idioma:</font>
                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'pt'}"
                       href="javascript:void('');"
                       (click)="switchLanguage('pt')">PT</a>
                    <span class="separator">|</span>
                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'es'}"
                       href="javascript:void('');"
                       (click)="switchLanguage('es')">ES</a>
                </div>
                <div class="header__btn mobile_notification notification_trigger">
                    <app-notification></app-notification>
                </div>
            </div>
        </li>
        <li class="nav-item " *ngFor="let m of menu">
            <a href="javascript:void('');" [routerLink]="[m.url]" class="nav-link text-white"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/{{m.icon}}"></svg-icon>
                <span class="lbl">{{m.name|translate}}</span>
            </a>
        </li>

        <!--        <li class="nav-item">-->
        <!--            <a href="javascript:void('Nota');" [routerLink]="['/medical-appointment']" class="nav-link text-white"-->
        <!--               (click)="actionCloseCollapse()">-->
        <!--                <svg-icon src="assets/svg/query.svg"></svg-icon>-->
        <!--                <span class="lbl">{{'menu.medical'|translate}}</span>-->
        <!--            </a>-->
        <!--        </li>-->
        <!--        <li class="nav-item ">-->
        <!--            <a href="javascript:void('Nota');" [routerLink]="['/suggestions']" class="nav-link text-white"-->
        <!--               (click)="actionCloseCollapse()">-->
        <!--                <svg-icon src="assets/svg/suggestions.svg"></svg-icon>-->
        <!--                <span class="lbl">{{'menu.suggestion'|translate}}</span>-->
        <!--            </a>-->
        <!--        </li>-->
        <!--        <li class="nav-item ">-->
        <!--            <a href="javascript:void('Nota');" [routerLink]="['/competence']" class="nav-link text-white"-->
        <!--               (click)="actionCloseCollapse()">-->
        <!--                <svg-icon src="assets/svg/competence.svg"></svg-icon>-->
        <!--                <span class="lbl">{{'menu.competence'|translate}}</span>-->
        <!--            </a>-->
        <!--        </li>-->
        <!--        <li class="nav-item ">-->
        <!--            <a href="javascript:void('Nota');" [routerLink]="['/budget-event']" class="nav-link text-white"-->
        <!--               (click)="actionCloseCollapse()">-->
        <!--                <svg-icon src="assets/svg/product.svg"></svg-icon>-->
        <!--                <span class="lbl">{{'menu.budget'|translate}}</span>-->
        <!--            </a>-->
        <!--        </li>-->
        <!--        <li class="nav-item ">-->
        <!--            <a href="javascript:void('Nota');" [routerLink]="['/product']" class="nav-link text-white"-->
        <!--               (click)="actionCloseCollapse()">-->
        <!--                <svg-icon src="assets/svg/product.svg"></svg-icon>-->
        <!--                <span class="lbl">{{'menu.product'|translate}}</span>-->
        <!--            </a>-->
        <!--        </li>-->
        <!--        <li class="nav-item ">-->
        <!--            <a href="javascript:void('Nota');" [routerLink]="['/line']" class="nav-link text-white"-->
        <!--               (click)="actionCloseCollapse()">-->
        <!--                <svg-icon src="assets/svg/product.svg"></svg-icon>-->
        <!--                <span class="lbl">{{'menu.line'|translate}}</span>-->
        <!--            </a>-->
        <!--        </li>-->
        <!--        <li class="nav-item ">-->
        <!--            <a href="javascript:void('Nota');" [routerLink]="['/user']" class="nav-link text-white"-->
        <!--               (click)="actionCloseCollapse()">-->
        <!--                <svg-icon class="svg-white" src="assets/svg/profile.svg"></svg-icon>-->
        <!--                <span class="lbl ">{{'menu.user'|translate}}</span>-->
        <!--            </a>-->
        <!--        </li>-->

        <!--        <li class="nav-item ">-->
        <!--            <a href="javascript:void('Nota');" [routerLink]="['/my-list']" class="nav-link text-white"-->
        <!--               (click)="actionCloseCollapse()">-->
        <!--                <span class="lbl">{{'sidebar.minhalista' | translate}}</span>-->
        <!--            </a>-->
        <!--        </li>-->


        <!--        <li class="nav-item ">-->
        <!--            <a href="javascript:void('User');" [routerLink]="['/user']" class="nav-link text-white"-->
        <!--               (click)="actionCloseCollapse()">-->
        <!--                <span class="lbl">{{'sidebar.user' | translate}}</span>-->
        <!--            </a>-->
        <!--        </li>-->

    </ul>
    <div class="logout d-lg-none">
        <a href="javascript:void('sair');" (click)="onLogout()">
            {{'sair' | translate}}
        </a>
    </div>

</nav>
