import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {
    Consult,
    ConsultResponseDto,
    Country,
    GetCountriesByUserGQL,
    GetCountriesByUserQuery,
    GetCountriesGQL,
    GetCountriesQuery,
    GetProductLinesGQL,
    GetProductLinesQuery,
    GetProductsByLineGQL,
    GetProductsByLineQuery,
    GetProductsGQL,
    GetProductsQuery,
    Product,
    ProductLine,
    ProductLinePage,
    RemoveConsultGQL,
    SearchConsultAll,
    SearchConsultAllGQL,
    SearchConsultAllQuery, SearchProduct,
    SearchProductLine,
    SearchProductLinePageGQL,
    SearchProductLinePageQuery, SearchProductLinesByCountryGQL, SearchProductLinesByCountryQuery,
    SuggestionResponseDto,
    User,
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {UserService} from '../../service/user.service';
import moment from 'moment';

declare var $;

@Component({
    selector: 'app-medical-appointment',
    templateUrl: './medical-appointment.component.html',
    styleUrls: ['./medical-appointment.component.scss']
})
export class MedicalAppointmentComponent extends BaseComponent implements OnInit, OnDestroy {
    title = 'Consultas Médicas';
    icon = 'query';

    lines: Array<ProductLine> = [];
    linesQuery: QueryRef<SearchProductLinesByCountryQuery>;

    user = new User();
    consults: Array<ConsultResponseDto> = [];
    consultsQuery: QueryRef<SearchConsultAllQuery>;

    productLines: Array<ProductLine>;
    productLinesQuery: QueryRef<GetProductLinesQuery>;

    products: Array<Product> = [];
    productsQuery: QueryRef<GetProductsQuery>;

    productsByLineQuery: QueryRef<GetProductsByLineQuery>;

    model: Consult = new Consult();
    ConsultProductLine: string;
    ConsultProduct: string;

    filterFront = new SearchConsultAll();
    filter = new SearchConsultAll();
    filterL = new SearchProductLine();

    countries: Array<Country>;
    countryQuery: QueryRef<GetCountriesQuery>;
    countriesUser: Array<Country>;
    countryUserQuery: QueryRef<GetCountriesByUserQuery>;

    filterCountries: Country[] = [];

    currentPage = 1;

    labels: any = {
        // previousLabel: '<--',
        previousLabel: ' ',
        // nextLabel: '-->',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    dropSettings: any = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Marcar todos',
        unSelectAllText: 'Desmarcar todos',
        itemsShowLimit: 3,
        allowSearchFilter: true
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                private searchConsultAllGQL: SearchConsultAllGQL,
                private getProductsGQl: GetProductsGQL,
                private getProductsByLineGQL: GetProductsByLineGQL,
                public searchProductLinePageGQL: SearchProductLinePageGQL,
                public searchProductLinesByCountryGQL: SearchProductLinesByCountryGQL,
                public getCountriesGQL: GetCountriesGQL,
                public getCountriesByUserGQL: GetCountriesByUserGQL,
                public removeConsult: RemoveConsultGQL,
                public userService: UserService,
                private getProductLinesGQl: GetProductLinesGQL,
    ) {
        super(router, loading, translate);
        this.filterFront.start = moment().startOf('day').subtract(30, 'days').utcOffset(0, true).format('DD/MM/YYYY');
        this.filterFront.end = moment().endOf('day').utcOffset(0, true).format('DD/MM/YYYY');
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                if (!this.isNullOrUndefined(this.user.id)) {
                    this.filter.keyword = null;
                    this.filter.line = null;
                    this.filter.product = null;
                    this.filter.status = null;

                    // this.getCountries();
                    // this.getProductLines();
                    this.setFilter();
                    this.getConsults();
                    this.getCountriesByUser();
                }
            }, error: err => super.onError(err)
        });

    }

    setFilter() {
        const start = moment(this.filterFront.start, 'DD/MM/YYYY');
        const end = moment(this.filterFront.end, 'DD/MM/YYYY');
        this.filter.start = moment(start).startOf('day').subtract(30, 'days').utcOffset(0, true).format();
        this.filter.end = moment(end).endOf('day').utcOffset(0, true).format();
    }

    ngOnDestroy(): void {
        const elModalConsult = $('#modalConsult');
        if (elModalConsult[0]) {
            elModalConsult.remove();
        }
    }


    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            this.countries = data.countries as Array<Country>;
        });
    }

    getCountriesByUser() {
        this.countryUserQuery = this.getCountriesByUserGQL.watch();
        this.countryUserQuery.valueChanges.subscribe(({data}) => {
            this.countriesUser = data.countriesByUser as Array<Country>;
        });
    }

    getConsults() {
        this.filter.countries = this.filterCountries.map(e => e.id);
        this.consultsQuery = this.searchConsultAllGQL.watch({input: this.filter});
        this.consultsQuery.valueChanges.subscribe(({data}) => {
            this.consults = data.searchConsultAll as Array<ConsultResponseDto>;
        });
    }

    getProductLines() {
        this.productLinesQuery = this.getProductLinesGQl.watch();
        // .pipe(map(result => result.data.productLines as Array<ProductLine>));
        this.productLinesQuery.valueChanges.subscribe(({data}) => {
            this.productLines = data.productLines as Array<ProductLine>;
        });
    }

    getProducts() {
        this.productsQuery = this.getProductsGQl.watch();
        // .pipe(map(result => result.data.products as Array<Product>));
        this.productsQuery.valueChanges.subscribe(({data}) => {
            this.products = data.products as Array<Product>;
        });
    }

    getLinesByCountries() {
        this.filterL.countries = this.filterCountries.map(e => e.id);
        this.linesQuery = this.searchProductLinesByCountryGQL.watch({id: this.filterL.countries});
        this.linesQuery.valueChanges.subscribe(({data}) => {
            this.lines = data.searchProductLinesByCountry as Array<ProductLine>;
        });
    }

    getProductsByLine() {
        this.productsByLineQuery = this.getProductsByLineGQL.watch({
            lines: this.filter.line
        });
        this.productsByLineQuery.valueChanges.subscribe(({data}) => {
            this.products = data.productsByLines as Array<Product>;
        });
        // .pipe(map(result => result.data.productsByLines as Array<Product>));
    }

    onFilter() {
        this.setFilter();
        this.filter.countries = this.filterCountries.map(e => e.id);
        this.consultsQuery.refetch({input: this.filter});
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        const start = moment(this.filter.start).format('YYYY-MM-DD');
        const end = moment(this.filter.end).format('YYYY-MM-DD');
        let url = `${environment.base_url}/consults/search-consult/excel?token=${token}`;
        if (!super.isNullOrUndefined(this.filter.keyword)) {
            url += ('&keyword=' + this.filter.keyword);
        }
        this.filter.countries = this.filterCountries.map(e => e.id);
        if (!super.isNullOrUndefined(this.filter.countries) && this.filter.countries.length > 0) {
            url += ('&countries=' + this.filter.countries.join('|'));
        }
        if (!super.isNullOrUndefined(this.filter.line)) {
            url += ('&line=' + this.filter.line);
        }
        if (!super.isNullOrUndefined(this.filter.product)) {
            url += ('&product=' + this.filter.product);
        }
        if (!super.isNullOrUndefined(this.filter.status)) {
            url += ('&status=' + this.filter.status);
        }
        if (!super.isNullOrUndefined(start)) {
            url += ('&start=' + start);
        }
        if (!super.isNullOrUndefined(end)) {
            url += ('&end=' + end);
        }
        window.open(encodeURI(url));
    }

    // controllerPage() {
    //     switch (this.router.url) {
    //         case '/medical-appointment':
    //             this.title = 'Consultas Médicas';
    //             this.icon = 'query';
    //             break;
    //         case '/suggestions':
    //             this.title = 'Sugerencias';
    //             this.icon = 'suggestions';
    //             break;
    //
    //         case '/competence':
    //             this.title = 'Competencia';
    //             this.icon = 'competence';
    //             break;
    //     }
    // }

    actionDelete(idProduct) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningConsultDelete'), () => {
            this.removeConsult.mutate({id: idProduct}).subscribe((result) => {
                this.consultsQuery.refetch();
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningConsultDeleteSuccess'), 'success');
            });
        });
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'medical-manager':
                return false;
            case 'district-manager':
                return false;
            case 'analyst-marketing':
                return false;
            case 'product-manager':
                return false;
            case 'marketing-manager':
                return false;
            case 'admin':
                return true;
            case 'admin-local':
                return true;
            case 'consult':
                return false;
        }
    }
}
