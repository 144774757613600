<div class="body">
    <div class="header">
        <h1 class="header-title">
            <svg-icon class="cursor-pointer" [routerLink]="'/competence'" src="assets/svg/arrow.svg"></svg-icon>
            {{'competencia'| translate}}
        </h1>
    </div>
    <!--'bg-primary' case answered-->
    <div class="card-info" [ngClass]="{
                                'bg-warning' : (competence.status === 'pending'),
                                'bg-primary bg-success' : (competence.status === 'answered')
                            }">
        <div class="d-flex flex-wrap" style="gap: 13px">
            <div class="d-flex flex-wrap" style="flex-grow: 3;gap: 10px">
                <dl class="flex-grow-1">
                    <dt class="">{{'nomelinha'|translate}}</dt>
                    <dd class="">{{competence.product?.productLine?.name}}</dd>
                </dl>
                <dl class="flex-grow-1">
                    <dt class="">{{'nomeproduto'|translate}}</dt>
                    <dd class="">{{competence.product?.name}}</dd>
                </dl>
                <dl class="flex-grow-1">
                    <dt class="">{{'gerency'|translate}}</dt>
                    <dd class=""><span *ngFor="let m of competence.product?.managers">{{m.user?.name}}<br/></span></dd>
                </dl>
                <dl class="flex-grow-1">
                    <dt class="">{{'criadoem'|translate}}</dt>
                    <dd class="">{{competence.createdAt | date: 'dd/MM/yyyy'}}</dd>
                </dl>
            </div>
            <div class="card-divider">&nbsp;</div>
            <div class="d-flex flex-wrap flex-grow-1">
                <dl class="flex-grow-1">
                    <dt>Status</dt>
                    <dd>{{getStatusNameByKey(competence.status)}}</dd>
                </dl>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column mt-5 border-after" style="gap: 90px">
        <div class="row" style="row-gap: 30px" *ngIf="competence.status === 'answered'">
            <div class="col-12 mb-3">
                <h5>Descrição</h5>
                <span>{{competence.description}}</span>
            </div>
            <app-card-profile class="col-md-4"
                              [image]="!competence?.answerBy?.photo ? 'assets/svg/person.svg' : competence?.answerBy?.photo"
                              [name]="competence?.answerBy?.name"
                              [unity]="getMangerTypeByKey(competence?.answerBy?.profile)">
            </app-card-profile>
            <div class="col-md-8">
                <span>{{competence.answeredAt | date: 'dd/MM/yyyy'}}</span>
                <br>
                <p class="body-paragraph">{{competence.answer}}</p>
            </div>
        </div>
        <div class="col-12 mb-3" *ngIf="competence.status !== 'answered'">
            <h5>Descrição</h5>
            <span>{{competence.description}}</span>
        </div>
        <div class="row" style="row-gap: 20px">
            <div class="col-sm-6 d-flex flex-column">
                <span class="body-label text-gray-text mb-2">{{'fotos'|translate}}</span>
                <div class="d-flex flex-wrap" style="gap:22px">
                    <ng-container *ngIf="listFilesImg.length === 0"><p
                            class="text-center py-5">{{'nenhumaimagem'|translate}}</p></ng-container>
                    <div class="image-box-md" *ngFor="let a of listFilesImg">
                        <a href="{{a.url}}" target="_blank">
                            <img [src]="a.url" alt="photo"/>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 d-flex flex-column">
                <span class="body-label text-gray-text mb-2">{{'arquivos'|translate}}</span>
                <div class="d-flex flex-wrap" style="gap:22px">
                    <ng-container *ngIf="listFiles.length === 0"><p
                            class="text-center py-5">{{'nenhumarquivo'|translate}}</p></ng-container>
                    <ng-container *ngFor="let a of listFiles">
                        <a href="{{a.url}}" target="_blank">
                            <span class="d-flex align-items-center text-muted" style="gap: 14px">
                                <svg-icon class="btn btn-outline-gray-variant" src="assets/svg/download.svg"></svg-icon>
                                {{'arquivo'|translate}}<span class="text-uppercase">{{a.type}}</span>
                            </span>
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-wrap ml-auto justify-content-end align-items-center mt-5 mb-3"
         style="gap: 30px;max-width: 764px;" *ngIf="competence.status !== 'answered'">
        <ng-container>
            <!--            <span class="body-label label-secondary">{{'responder'|translate}}</span>-->
            <!--            <div class="input-material" style="flex-grow: 6;">-->
            <!--                <select id="linear" class="form-control" name="type" [(ngModel)]="competenceAnswer.answer">-->
            <!--                    <option selected-->
            <!--                            [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>-->
            <!--                    <option [ngValue]="'Resposta A'">Resposta A</option>-->
            <!--                    <option [ngValue]="'Resposta B'">Resposta B</option>-->
            <!--                    <option [ngValue]="'Resposta C'">Resposta C</option>-->
            <!--                </select>-->
            <!--                <label for="linear" class="mb-0 mr-3">{{'mensagem'|translate}}</label>-->
            <!--            </div>-->

            <div class="col-md-8">
                <div class="d-flex justify-content-between flex-grow-1 mb-4" style="gap: 15px;">
                    <h2 class="body-title" style="gap: 0px">
                        {{'resposta'|translate}}
                    </h2>
                    <!--ANSWER-->
                </div>
                <p *ngIf="competence.status === 'answered'" class="body-paragraph">
                    {{competence.answer.toString()}}
                </p>
                <div class="input-material" *ngIf="competence.status !== 'answered' && selectProfile()">
                    <textarea id="answer" [(ngModel)]="competenceAnswer.answer"></textarea>
                    <label for="answer"> {{'respondaaqui'|translate}}</label>

                </div>
            </div>

            <button class="btn btn-warning px-5 w-50" (click)="actionAnswerCompetence()"
                    *ngIf=selectProfile()>{{'responder'|translate}}</button>
        </ng-container>
        <app-card-profile
                *ngIf="false"
                [borderImage]="'border-warning'"
                [image]="'assets/svg/person.svg'"
                [name]="'Fernanda de Mello'"
                [unity]="'Unidade de Itapevi'">
        </app-card-profile>

    </div>
</div>
