import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {
    Budget,
    BudgetGQL,
    BudgetQuery,
    UpdateBudgetStatusGQL,
    UpdateBudgetStatusInput, User
} from '../../../generated/graphql';
import {TranslateService} from '@ngx-translate/core';
import {underscore} from 'underscore';
import {QueryRef} from 'apollo-angular';
import {UserService} from '../../service/user.service';

declare var $: any;
declare var _: underscore;

@Component({
    selector: 'app-budget-event-detail',
    templateUrl: './budget-event-detail.component.html',
    styleUrls: ['./budget-event-detail.component.scss']
})
export class BudgetEventDetailComponent extends BaseComponent implements OnInit {
    user = new User();
    paramId = null;
    budget: Budget = new Budget();
    budgetQuery: QueryRef<BudgetQuery>;
    budgetUpdateStatus: UpdateBudgetStatusInput = new UpdateBudgetStatusInput();
    groupedArrayByProductLine: any = {};

    constructor(public router: Router,
                public router2: ActivatedRoute,
                public loading: NgxSpinnerService,
                public budgetGQL: BudgetGQL,
                public userService: UserService,
                public updateBudgetStatusGQL: UpdateBudgetStatusGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        this.router2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                this.paramId = url.id;
                this.budgetQuery = this.budgetGQL.watch({id: this.paramId});
                this.budgetQuery.valueChanges.subscribe(({data}) => {
                    this.budget = data.budget as Budget;
                    this.groupedArrayByProductLine = Object.entries(_(this.budget.product).groupBy(o => o.productLine.name));
                });
            }
        });
    }

    actionModal(modal) {
        $(modal).modal('show');
    }

    actionChangeStatus(s: string) {
        this.budgetUpdateStatus.id = this.paramId;
        this.budgetUpdateStatus.status = (s === 'approved' && this.budget.status === 'pending') ? 'pending-product' : s;
        const a: any = ['revision', 'disapproved'];
        if (a.includes(s)) {
            const arrValidateFields = [
                {value: this.budgetUpdateStatus.response, text: 'Justificativa'}
            ];
            const stringError = this.validateField(arrValidateFields);
            if (!super.isNullOrUndefined(stringError)) {
                super.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')}<br> ${stringError}`);
                return;
            }
        }
        this.onConfirmChangeStatus();
    }

    onConfirmChangeStatus() {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningContinue'), () => {
            this.updateBudgetStatusGQL.mutate({input: this.budgetUpdateStatus}).subscribe((result) => {
                this.budgetQuery.refetch({id: this.paramId});
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningUpdateSuccess'), 'success');
                this.closeModal('modalReject');
                this.closeModal('modalReview');
            });
        });
    }

    actionUserProfileStatus() {
        switch (this.user.profile) {
            case 'medical-manager':
                return false;
            case 'district-manager':
                return !(this.budget.status === 'pending-product');
            case 'analyst-marketing':
                return false;
            case 'product-manager':
                return true;
            case 'marketing-manager':
                return true;
            case 'admin':
                return true;
            case 'admin-local':
                return true;
            case 'consult':
                return false;
            default:
                return true;
        }
    }
}
