import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {
    Budget,
    CitiesGQL,
    CitiesQuery,
    GetProductLinesGQL,
    GetUnitiesGQL,
    GetUnitiesQuery,
    Product,
    GetProductLinesQuery,
    ProductsByLinesGQL,
    ProductsByLinesQuery,
    ProductsGQL,
    ProductsQuery,
    SearchBudgetAll,
    SearchBudgetsGQL,
    SearchBudgetsQuery,
    Unity,
    Country,
    GetCountriesQuery,
    GetCountriesGQL,
    User,
    RemoveCompetenceGQL,
    RemoveBudgetGQL,
    GetCountriesByUserQuery,
    GetCountriesByUserGQL,
    GetCitiesByCountriesGQL,
    City,
    GetCitiesByCountriesQuery,
    ProductLine,
    SearchProductLine,
    SearchProductLinesByCountryQuery,
    SearchProductLinesByCountryGQL,
    GetProductsByLineQuery,
    GetProductsByLineGQL,
    BudgetPage
} from '../../../generated/graphql';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {QueryRef} from 'apollo-angular';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {UserService} from '../../service/user.service';
import moment from 'moment';

declare var $;

@Component({
    selector: 'app-budget-event',
    templateUrl: './budget-event.component.html',
    styleUrls: ['./budget-event.component.scss']
})
export class BudgetEventComponent extends BaseComponent implements OnInit {

    user = new User();

    searchBudgetQuery: QueryRef<SearchBudgetsQuery>;
    filterFront: SearchBudgetAll = new SearchBudgetAll();
    filter: SearchBudgetAll = new SearchBudgetAll();
    result: BudgetPage = new BudgetPage();

    productQuery: QueryRef<ProductsByLinesQuery>;
    product: Array<Product>;

    productsByLineQuery: QueryRef<GetProductsByLineQuery>;
    products: Array<Product> = [];

    citiesQuery: QueryRef<CitiesQuery>;
    city: any;

    // lineQuery: QueryRef<GetProductLinesQuery>;
    // line: any;

    lines: Array<ProductLine> = [];
    linesQuery: QueryRef<SearchProductLinesByCountryQuery>;

    unities: Observable<Array<Unity>>;
    unityQuery: QueryRef<GetUnitiesQuery>;

    countries: Array<Country>;
    countryQuery: QueryRef<GetCountriesQuery>;

    filterL = new SearchProductLine();

    filterCountries: Country[] = [];
    selectedCountries: Country[] = [];

    countriesUser: Array<Country>;
    countryUserQuery: QueryRef<GetCountriesByUserQuery>;

    cityByCountry: Array<City>;
    cityCountryQuery: QueryRef<GetCitiesByCountriesQuery>;

    dropSettings: any = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Marcar todos',
        unSelectAllText: 'Desmarcar todos',
        itemsShowLimit: 3,
        allowSearchFilter: true
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                private searchBudgetsGQL: SearchBudgetsGQL,
                private productsByLinesGQL: ProductsByLinesGQL,
                private citiesGQL: CitiesGQL,
                private getProductLinesGQL: GetProductLinesGQL,
                private getProductsByLineGQL: GetProductsByLineGQL,
                private getUnitiesGQL: GetUnitiesGQL,
                public getCountriesGQL: GetCountriesGQL,
                public searchProductLinesByCountryGQL: SearchProductLinesByCountryGQL,
                public getCountriesByUserGQL: GetCountriesByUserGQL,
                public getCitiesByCountriesGQL: GetCitiesByCountriesGQL,
                private removeBudget: RemoveBudgetGQL,
                public userService: UserService,
                public translate: TranslateService,
    ) {
        super(router, loading, translate);
        this.filterFront.start = moment().startOf('day').subtract(30, 'days').utcOffset(0, true).format('DD/MM/YYYY');
        this.filterFront.end = moment().endOf('day').utcOffset(0, true).format('DD/MM/YYYY');
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                if (!this.isNullOrUndefined(this.user.id)) {
                    this.filter.byEvent = true;
                    this.setFilter();
                    this.searchBudgetQuery = this.searchBudgetsGQL.watch({filter: this.filter});
                    this.searchBudgetQuery.valueChanges.subscribe(({data}) => {
                        this.result = data.searchBudgets as BudgetPage;
                        this.getCountriesByUser();
                    });
                }
            }, error: err => super.onError(err)
        });

    }

    setFilter() {
        const start = moment(this.filterFront.start, 'DD/MM/YYYY');
        const end = moment(this.filterFront.end, 'DD/MM/YYYY');
        this.filter.start = moment(start).startOf('day').subtract(30, 'days').utcOffset(0, true).format();
        this.filter.end = moment(end).endOf('day').utcOffset(0, true).format();
    }

    onFilter() {
        this.setFilter();
        this.filter.page = 1;
        this.filter.countries = this.selectedCountries.map(e => e.id);
        this.searchBudgetQuery.refetch({filter: this.filter});
    }

    convertUndefined(obj) {
        obj.map((e) => {
            if (this.isNullOrUndefined(e)) {
                e = '';
            }
        });
        return obj;
    }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            this.countries = data.countries as Array<Country>;
        });
    }

    getCountriesByUser() {
        this.countryUserQuery = this.getCountriesByUserGQL.watch();
        this.countryUserQuery.valueChanges.subscribe(({data}) => {
            this.countriesUser = data.countriesByUser as Array<Country>;

        });
    }

    getLinesByCountries() {
        this.filterL.countries = this.selectedCountries.map(e => e.id);
        this.linesQuery = this.searchProductLinesByCountryGQL.watch({id: this.filterL.countries});
        this.linesQuery.valueChanges.subscribe(({data}) => {
            this.lines = data.searchProductLinesByCountry as Array<ProductLine>;
        });
    }

    getProductsByLine() {
        this.filter.product = undefined;
        this.productsByLineQuery = this.getProductsByLineGQL.watch({
            lines: this.filter.line
        });
        this.productsByLineQuery.valueChanges.subscribe(({data}) => {
            this.products = data.productsByLines as Array<Product>;
        });
        // .pipe(map(result => result.data.productsByLines as Array<Product>));
    }

    getCityByCountries() {
        this.cityCountryQuery = this.getCitiesByCountriesGQL.watch();
        this.cityCountryQuery.valueChanges.subscribe(({data}) => {
            this.cityByCountry = data.citiesByCountries as Array<City>;
        });
    }

    getUnitiesFilter() {
        this.filter.city = undefined;
        this.filter.line = undefined;
        const listId = [];
        this.selectedCountries.forEach((e) => {
            return listId.push(e.id);
        });
        this.cityCountryQuery = this.getCitiesByCountriesGQL.watch(
            {ids: listId});
        this.cityCountryQuery.valueChanges.subscribe(({data}) => {
            this.cityByCountry = data.citiesByCountries as Array<City>;
        });
        this.getLinesByCountries();
        console.log('chamou');
        console.log(this.selectedCountries);
    }

    // getLines() {
    //     this.lineQuery = this.getProductLinesGQL.watch();
    //     this.lineQuery.valueChanges.subscribe(({data}) => {
    //         this.line = data.productLines;
    //     });
    // }

    getCities() {
        this.citiesQuery = this.citiesGQL.watch();
        this.citiesQuery.valueChanges.subscribe(({data}) => {
            this.city = data.cities;
        });
    }

    getProducts() {
        this.productQuery = this.productsByLinesGQL.watch({lines: []});
        this.productQuery.valueChanges.subscribe(({data}) => {
            this.product = data.productsByLines as Array<Product>;
        });
    }

    onProducts() {
        this.productQuery.refetch({lines: this.filter.line === undefined ? [] : [this.filter.line]});
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        const start = moment(this.filter.start).format('YYYY-MM-DD');
        const end = moment(this.filter.end).format('YYYY-MM-DD');
        let url = `${environment.base_url}/budgets/search-budget/excel?token=${token}`;
        if (!super.isNullOrUndefined(this.filter.byEvent)) {
            url += ('&byEvent=' + this.filter.byEvent);
        }
        if (!super.isNullOrUndefined(this.filter.keyword)) {
            url += ('&keyword=' + this.filter.keyword);
        }
        if (!super.isNullOrUndefined(this.filter.line)) {
            url += ('&line=' + this.filter.line);
        }
        // if (!super.isNullOrUndefined(this.filter.unity)) {
        //     url += ('&unity=' + this.filter.unity);
        // }
        if (!super.isNullOrUndefined(this.filter.product)) {
            url += ('&product=' + this.filter.product);
        }
        if (!super.isNullOrUndefined(this.filter.status)) {
            url += ('&status=' + this.filter.status);
        }
        if (!super.isNullOrUndefined(this.filter.city)) {
            url += ('&city=' + this.filter.city);
        }
        if (!super.isNullOrUndefined(start)) {
            url += ('&start=' + start);
        }
        if (!super.isNullOrUndefined(end)) {
            url += ('&end=' + end);
        }

        this.filter.countries = this.selectedCountries.map(e => e.id);
        console.log('paises: ', this.selectedCountries);
        if (!super.isNullOrUndefined(this.filter.countries) || this.filter.countries.length > 0) {
            url += ('&countries=' + this.filter.countries.join('|'));
        }
        console.log(url);

        window.open(encodeURI(url));
    }

    actionDelete(idProduct) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningBudgetDelete'), () => {
            this.removeBudget.mutate({id: idProduct}).subscribe((result) => {
                this.searchBudgetQuery.refetch();
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningBudgetDeleteSuccess'), 'success');
            });
        });
    }

    pageChanged(event) {
        this.filter.page = event;
        this.searchBudgetQuery.refetch({input: this.filter});
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'medical-manager':
                return false;
            case 'district-manager':
                return false;
            case 'analyst-marketing':
                return false;
            case 'product-manager':
                return false;
            case 'marketing-manager':
                return false;
            case 'admin':
                return true;
            case 'admin-local':
                return true;
            case 'consult':
                return false;
        }
    }
}
