import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {
    AnswerConsultGQL,
    AnswerConsultInput, Competence,
    Consult, ConsultFile, ConsultResponseDto, CurrentUserGQL, CurrentUserQuery,
    GetConsultGQL,
    GetConsultLikesGQL, GetConsultLikesQuery,
    GetConsultQuery, User
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {map} from 'rxjs/operators';
import {UserService} from "../../service/user.service";

@Component({
    selector: 'app-medical-appointment-detail',
    templateUrl: './medical-appointment-detail.component.html',
    styleUrls: ['./medical-appointment-detail.component.scss']
})
export class MedicalAppointmentDetailComponent extends BaseComponent implements OnInit {
    user = new User();
    paramId = null;
    consultDto: ConsultResponseDto = new ConsultResponseDto();

    consultLikeQuery: QueryRef<GetConsultLikesQuery>;

    currentUser: User = new User();

    consultAnswer = new AnswerConsultInput();

    listFiles = [];
    listFilesImg = [];

    constructor(public router: Router,
                public router2: ActivatedRoute,
                public userService: UserService,
                public loading: NgxSpinnerService,
                public consultLikeGQL: GetConsultLikesGQL,
                public answerConsultGQL: AnswerConsultGQL,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit() {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });

        this.userService.user.subscribe({
            next: data => {
                this.currentUser = data as User;
            }, error: err => super.onError(err)
        });

        this.router2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
                this.paramId = url.id;
                this.consultLikeQuery = this.consultLikeGQL.watch({id: this.paramId});
                this.consultLikeQuery.valueChanges.subscribe(({data}) => {
                    this.consultDto = data.consultLikes as ConsultResponseDto;
                    this.listFiles = this.consultDto.consult.file.filter((x) => x.type !== 'jpg' && x.type !== 'png');
                    this.listFilesImg = this.consultDto.consult.file.filter((x) => x.type === 'jpg' || x.type === 'png');
                });
            }
        });
        this.consultAnswer.answer = null;
        this.consultAnswer.id = this.paramId;
    }

    actionAnswerConsult() {
        if (this.isNullOrUndefined(this.consultAnswer.answer) || this.consultAnswer.answer === '') {
            super.showMessage(this.translate.instant('baseComponent.warningAttention'),
                this.translate.instant('escrevaresposta'), 'warning');
            return;
        }
        this.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('prosseguir'), () => {
            this.answerConsult();
        });
    }

    answerConsult() {
        this.consultAnswer.id = this.paramId;
        this.answerConsultGQL.mutate({input: this.consultAnswer}).subscribe((result) => {
            this.consultLikeQuery.refetch({id: this.paramId}).then();
            super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningUpdateSuccess'), 'success');
            // this.router.navigate(['/medical-appointment']);
        });
    }

    selectProfile() {
        switch (this.user.profile) {
            case 'medical-manager':
                return true;
            case 'district-manager':
                return false;
            case 'analyst-marketing':
                return false;
            case 'product-manager':
                return true;
            case 'marketing-manager':
                return true;
            case 'admin':
            case 'admin-local':
                return true;
            case 'consult':
                return false;
        }
    }
}
