import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {LogoutComponent} from './views/logout/logout.component';
import {HomeComponent} from './views/home/home.component';
import {UserComponent} from './views/user/user.component';
import {AuthenticateComponent} from './views/authenticate/authenticate.component';
import {MedicalAppointmentComponent} from './views/medical-appointment/medical-appointment.component';
import {MedicalAppointmentDetailComponent} from './views/medical-appointment-detail/medical-appointment-detail.component';
import {CompetenceDetailComponent} from './views/competence-detail/competence-detail.component';
import {SuggestionsComponent} from './views/suggestions/suggestions.component';
import {SuggestionsDetailComponent} from './views/suggestions-detail/suggestions-detail.component';
import {BudgetEventComponent} from './views/budget-event/budget-event.component';
import {BudgetEventDetailComponent} from './views/budget-event-detail/budget-event-detail.component';
import {ProductComponent} from './views/product/product.component';
import {LineComponent} from './views/line/line.component';
import {CompetenceComponent} from './views/competence/competence.component';

const routes: Routes = [
    {path: 'authenticate', component: AuthenticateComponent},
    {path: 'logout', component: LogoutComponent},
    {
        path: '', component: MainComponent,
        children: [
            {path: '', component: HomeComponent},
            // {path: 'register', component: RegisterComponent},
            // {path: 'register/:id', component: RegisterComponent},
            // {path: 'register-detail', component: RegisterDetailComponent},
            {path: 'home', component: HomeComponent},
            {path: 'user', component: UserComponent},
            {path: 'medical-appointment', component: MedicalAppointmentComponent},
            {path: 'medical-appointment/:id', component: MedicalAppointmentDetailComponent},
            {path: 'suggestions', component: SuggestionsComponent},
            {path: 'suggestions/:id', component: SuggestionsDetailComponent},
            {path: 'competence', component: CompetenceComponent},
            {path: 'competence/:id', component: CompetenceDetailComponent},
            {path: 'budget-event', component: BudgetEventComponent},
            {path: 'budget-event/:id', component: BudgetEventDetailComponent},
            {path: 'product', component: ProductComponent},
            {path: 'line', component: LineComponent},
        ],
        // canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
