<div class="body">
    <div class="header">
        <h1 class="header-title">
            <svg-icon class="cursor-pointer" routerLink="/medical-appointment" src="assets/svg/arrow.svg"></svg-icon>
            Consultas
        </h1>
    </div>
    <!--CARD COLOR-->
    <div class="card-info" [ngClass]="{
                                'card-info-pending' : (consultDto.consult?.status === 'pending'),
                                'card-info-answered' : (consultDto.consult?.status === 'answered')
                            }">
        <div class="d-flex flex-wrap" style="gap: 13px">
            <div class="d-flex flex-wrap" style="flex-grow: 3;">
                <!--LINE-->
                <dl class="flex-grow-1">
                    <dt class="">{{'nomelinha'|translate}}</dt>
                    <dd class="">{{consultDto.consult?.product?.productLine?.name}}</dd>
                </dl>
                <!--PRODUCT-->
                <dl class="flex-grow-1">
                    <dt class="">{{'nomeproduto'|translate}}</dt>
                    <dd class="">{{consultDto.consult?.product?.name}}</dd>
                </dl>
            </div>
            <div class="card-divider">&nbsp;</div>
            <div class="d-flex flex-wrap flex-grow-1">
                <!--STATUS-->
                <dl class="flex-grow-1">
                    <dt>Status</dt>
                    <dd>{{getStatusNameByKey(consultDto.consult?.status)}}</dd>
                </dl>
                <!--LIKES-->
                <dl class="flex-grow-1">
                    <dt>{{'like' | translate}}</dt>
                    <div class="d-flex justify-content-between align-items-center"
                         style="gap:5px;max-width: 87px;">
                        <div class="d-flex align-items-center" style="gap: 7px">
                            <svg-icon src="assets/svg/like.svg"></svg-icon>
                            {{consultDto.likes}}
                        </div>
                        <div class="d-flex align-items-center" style="gap: 7px">
                            <svg-icon src="assets/svg/dislike.svg"></svg-icon>
                            {{consultDto.dislikes}}
                        </div>
                    </div>
                </dl>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column mt-5" style="gap: 70px">
        <!--QUESTIONER-->
        <div class="row" style="row-gap: 30px">
            <app-card-profile class="col-md-4"
                              [image]="consultDto.consult?.createdBy?.photo"
                              [name]="consultDto.consult?.createdBy?.name"
                              [unity]="getMangerTypeByKey(consultDto.consult?.createdBy?.profile)">
            </app-card-profile>
            <div class="col-md-8 border-after">
                <div class="d-flex justify-content-between flex-grow-1 mb-4" style="gap: 15px;">
                    <h2 class="body-title">
                        <svg-icon src="assets/svg/query.svg"></svg-icon>
                        {{'pergunta'|translate}}
                    </h2>
                    <!--CONSULT DATE-->
                    <span class="body-createAt">{{consultDto.consult?.createdAt | date: 'dd/MM/yyyy'}}</span>
                </div>
                <!--QUESTION-->
                <p class="body-paragraph">{{consultDto.consult?.description}}</p>
            </div>
        </div>
        <!--ANSWERER-->
        <div class="row" style="row-gap: 30px">
            <app-card-profile class="col-md-4"
                              [borderImage]="'border-warning'"
                              [image]="consultDto.consult?.status === 'answered' ? consultDto.consult?.answerBy?.photo : currentUser.photo"
                              [name]="consultDto.consult?.status === 'answered' ? consultDto.consult?.answerBy?.name : currentUser.name"
                              [unity]="consultDto.consult?.status === 'answered' ? getMangerTypeByKey(consultDto.consult?.answerBy?.profile) : getMangerTypeByKey(currentUser.profile)">
            </app-card-profile>
            <div class="col-md-8">
                <div class="d-flex justify-content-between flex-grow-1 mb-4" style="gap: 15px;">
                    <h2 class="body-title text-gray-text">
                        <svg-icon src="assets/svg/profile.svg"></svg-icon>
                        {{'resposta'|translate}}
                    </h2>

                    <span class="body-createAt">{{consultDto.consult?.answeredAt | date: 'dd/MM/yyyy'}}</span>
                <!--ANSWER-->
                </div>
                <p *ngIf="consultDto.consult?.status === 'answered'" class="body-paragraph">
                    {{consultDto.consult?.answer}}
                </p>
                <p *ngIf="consultDto.consult?.status !== 'answered'" class="body-paragraph ml-4">
                    {{'unanswered-questions'|translate}}
                </p>
                <div class="input-material" *ngIf="consultDto.consult?.status !== 'answered' && selectProfile()">
                    <textarea id="response"[(ngModel)]="consultAnswer.answer"></textarea>
                    <label for="response">{{'respondaaqui'|translate}}</label>
                </div>
            </div>
        </div>

        <div class="row" style="row-gap: 20px">
            <div class="col-sm-6 d-flex flex-column">
                <span class="body-label text-gray-text mb-2">{{'fotos'|translate}}</span>
                <div class="d-flex flex-wrap" style="gap:22px">
                    <ng-container *ngIf="listFilesImg.length === 0"><p
                            class="text-center py-5">{{'nenhumaimagem'|translate}}</p></ng-container>
                    <div class="image-box-md" *ngFor="let a of listFilesImg">
                        <a href="{{a.url}}" target="_blank">
                            <img [src]="a.url" alt="photo"/>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 d-flex flex-column">
                <span class="body-label text-gray-text mb-2">{{'arquivos'|translate}}</span>
                <div class="d-flex flex-wrap" style="gap:22px">
                    <ng-container *ngIf="listFiles.length === 0"><p
                            class="text-center py-5">{{'nenhumarquivo'|translate}}</p></ng-container>
                    <ng-container *ngFor="let a of listFiles">
                        <a href="{{a.url}}" target="_blank">
                            <span class="d-flex align-items-center text-muted" style="gap: 14px">
                                <svg-icon class="btn btn-outline-gray-variant" src="assets/svg/download.svg"></svg-icon>
                                {{'arquivo'|translate}}<span class="text-uppercase">{{a.type}}</span>
                            </span>
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-end mt-4 mb-3" *ngIf="selectProfile()">
        <button class="btn btn-warning px-5" (click)="actionAnswerConsult()" *ngIf="consultDto.consult?.status !=='answered'">{{'responder'|translate}}</button>

    </div>
</div>
