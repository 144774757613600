import {Component, OnDestroy, OnInit, AfterContentInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {
    User,
    Country,
    GetCountriesGQL,
    GetCountriesQuery,
    // Unity,
    // GetUnitiesQuery,
    // GetUnitiesGQL,
    SearchUserAllGQL,
    SearchUserAllQuery,
    CreateUserGQL,
    UpdateUserGQL,
    RemoveUserGQL,
    CreateUserInput,
    SearchUserAll,
    UpdateUserInput,
    SearchUserQuery,
    SearchUserGQL,
    UserPage,
    GetCountriesByUserQuery,
    GetCountriesByUserGQL, CreateUserCountryInput
} from '../../../generated/graphql';

import {QueryRef} from 'apollo-angular';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {UserService} from '../../service/user.service';

declare var $: any;

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements AfterContentInit, OnDestroy {
    user = new User();
    users: UserPage;
    usersQuery: QueryRef<SearchUserQuery>;

    countries: Array<Country> = [];
    countryQuery: QueryRef<GetCountriesQuery>;

    countriesUser: Array<Country> = [];
    countryUserQuery: QueryRef<GetCountriesByUserQuery>;

    // unities: Observable<Array<Unity>>;
    // unityQuery: QueryRef<GetUnitiesQuery>;

    model: User = new User();
    // userCountry: string;
    userUnity: string;

    userCountries: Country[] = [];
    filterCountries: Country[] = [];

    filter = new SearchUserAll();

    // keyword = '';
    // countrySelected: string = null;
    // profileSelected: string = null;
    // unitySelected: string = null;

    labels: any = {
        // previousLabel: '<--',
        previousLabel: ' ',
        // nextLabel: '-->',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    dropSettings: any = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Marcar todos',
        unSelectAllText: 'Desmarcar todos',
        itemsShowLimit: 8,
        allowSearchFilter: true
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                private searchUserGQL: SearchUserGQL,
                private getCountriesGQL: GetCountriesGQL,
                public getCountriesByUserGQL: GetCountriesByUserGQL,
                // private getUnitiesGQL: GetUnitiesGQL,
                private createUserGQL: CreateUserGQL,
                private updateUserGQL: UpdateUserGQL,
                public userService: UserService,
                private removeUserGQL: RemoveUserGQL
    ) {
        super(router, loading, translate);
    }

    ngAfterContentInit() {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;

                this.getCountries();
                this.getCountriesByUser();
                this.getUsers();

            }, error: err => super.onError(err)
        });

        this.setDatePicker('picker-period', (start, end, label) => {
            // this.modelFilter.start = start.utcOffset(0, true).format();
            // this.modelFilter.end = end.utcOffset(0, true).format();
        });


    }

    ngOnDestroy(): void {
        const elModalUser = $('#modalUser');
        if (elModalUser[0]) {
            elModalUser.remove();
        }
    }

    getUsers() {
        this.usersQuery = this.searchUserGQL.watch({input: this.filter});
        // this.users = this.usersQuery.valueChanges.pipe(map(result => result.data.searchUsersAll as Array<User>));
        this.usersQuery.valueChanges.subscribe(({data}) => {
            this.users = data.searchUsers as UserPage;
        });
    }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            this.countries = data.countries as Country[];
        }, error => super.onError(error));
    }

    getCountriesByUser() {
        this.countryUserQuery = this.getCountriesByUserGQL.watch();
        this.countryUserQuery.valueChanges.subscribe(({data}) => {
            this.countriesUser = data.countriesByUser as Country[];
        }, error => super.onError(error));
    }

    // getUnities() {
    //     this.unityQuery = this.getUnitiesGQL.watch();
    //     this.unities = this.unityQuery.valueChanges.pipe(map(result => result.data.unities as Array<Unity>));
    // }

    onFilter() {
        this.filter.page = 1;
        this.filter.countries = this.filterCountries.map(e => e.id);
        this.usersQuery.refetch({input: this.filter});
    }

    actionNew() {
        this.userCountries = null;
        this.model = new User();
        this.showModal('modalUser');
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: `${this.translate.instant('nome')}<br>`},
            {value: this.model.re, text: 'RE<br>'},
            {value: this.model.email, text: 'Email<br>'},
            {value: this.model.profile, text: 'Perfil<br>'},
            {value: this.userCountries, text: 'País<br>'},
            // {value: this.userUnity, text: `${this.translate.instant('unidade')}<br>`}
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }

        if (!this.isEMailValid(this.model.email)) {
            return;
        }

        if (super.isNullOrUndefined(this.model.id)) {
            this.actionCreatedUser();
        } else {
            this.actionUpdateUser();
        }
    }

    actionEdit(user: User) {
        Object.assign(this.model, user);
        this.userCountries = this.model.countries;
        console.log(this.model.countries);
        this.userUnity = this.model.unity?.id;
        this.showModal('modalUser');
    }

    actionCreatedUser() {
        const countries: CreateUserCountryInput[] = [];
        this.userCountries.forEach((e) => {
            countries.push({id: e.id});
        });
        const data: CreateUserInput = new CreateUserInput();
        data.profile = this.model.profile;
        data.name = this.model.name;
        data.email = this.model.email;
        data.countries = countries;
        data.re = this.model.re;
        // data.unityId = this.userUnity;

        this.createUserGQL.mutate({input: data}).subscribe(() => {
            this.model = new User();
            this.usersQuery.refetch();
            this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('usuariocriado'), 'success');
            this.closeModal('modalUser');
        }, error => super.onError(error));


    }

    actionUpdateUser() {
        const countries: CreateUserCountryInput[] = [];
        this.userCountries.forEach((e) => {
            countries.push({id: e.id});
        });
        const data = new UpdateUserInput();
        data.id = this.model.id;
        data.profile = this.model.profile;
        data.name = this.model.name;
        data.email = this.model.email;
        data.countries = countries;
        data.re = this.model.re;
        // data.unityId = this.userUnity;

        this.updateUserGQL.mutate({input: data}).subscribe(() => {
            this.model = new User();
            this.usersQuery.refetch();
            this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('usuarioeditado'), 'success');
            this.closeModal('modalUser');
        });
    }

    actionDelete(userId) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningUserDelete'), () => {
            this.removeUserGQL.mutate({id: userId}).subscribe((result) => {
                this.usersQuery.refetch();
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningUserDeleteSucess'), 'success');
            });
        });
    }

    pageChanged(event) {
        this.filter.page = event;
        this.usersQuery.refetch({input: this.filter});
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        let url = `${environment.base_url}/users/search-user/excel?token=${token}`;
        url += ('&culture=' + this.translate.currentLang);
        if (!super.isNullOrUndefined(this.filter.keyword)) {
            url += ('&keyword=' + this.filter.keyword);
        }
        if (!super.isNullOrUndefined(this.filter.profile)) {
            url += ('&profile=' + this.filter.profile);
        }
        if (!super.isNullOrUndefined(this.filter.unity)) {
            url += ('&unity=' + this.filter.unity);
        }
        if (!super.isNullOrUndefined(this.filterCountries)) {
            url += ('&countries=');
            this.filterCountries.forEach((e, index) => {
                url += ((index !== 0 ? '|' : '') + e.id);
            });
        }
        window.open(encodeURI(url));
    }

    imporExcel(event: any) {
        const files = event.target.files;
        this.userService.uploadImport('users', files[0]).subscribe((data) => {
            this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('importSuccess'), 'success');
        }, error => super.onErrorRest(error));
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'medical-manager':
                return false;
            case 'district-manager':
                return false;
            case 'analyst-marketing':
                return false;
            case 'product-manager':
                return false;
            case 'marketing-manager':
                return false;
            case 'admin':
                return true;
            case 'admin-local':
                return true;
            case 'consult':
                return false;
        }
    }

    getPosition(i: number) {
        switch (i) {
            case 0:
                return '0';
            case 1:
                return '-12.5px';
            case 2:
                return '-25px';
            case 3:
                return '-37.5px';
            default:
                return '-37.5px';
        }
    }


}
