import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../generated/graphql';
import {UserService} from '../../service/user.service';
// import {User} from "../../model/user/user";
// import {UserService} from "../../service/user.service";

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends BaseComponent implements OnInit, AfterViewInit {
    user: User;
    navBarShort = false;
    btnNew = false;

    menu: MenuItem[] = [];

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public userService: UserService) {

        super(router, loading, translate);
    }

    ngOnInit(): void {
        this.handlerCollapse();
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.btnNew = data.profile === 'local' || data.profile === 'administrator';
                this.checkMenu();
            }, error: err => super.onError(err)
        });

    }

    checkMenu() {
        const dashboard: MenuItem = {name: 'menu.dashboard', url: '/', icon: 'dashboard.svg'};
        const medical: MenuItem = {name: 'menu.medical', url: '/medical-appointment', icon: 'query.svg'};
        const suggestion: MenuItem = {name: 'menu.suggestion', url: '/suggestions', icon: 'suggestions.svg'};
        const competence: MenuItem = {name: 'menu.competence', url: '/competence', icon: 'competence.svg'};
        const budget: MenuItem = {name: 'menu.budget', url: '/budget-event', icon: 'product.svg'};
        const product: MenuItem = {name: 'menu.product', url: '/product', icon: 'medic.svg'};
        const line: MenuItem = {name: 'menu.line', url: '/line', icon: 'line.svg'};
        const user: MenuItem = {name: 'menu.user', url: '/user', icon: 'users.svg'};

        this.menu = [];

        switch (this.user.profile) {
            case 'medical-manager':
                // this.menu.push(medical);
                this.menu.push(dashboard);
                this.menu.push(medical);
                this.menu.push(suggestion);
                this.menu.push(competence);
                this.menu.push(budget);
                break;
            case 'district-manager':
                /*this.menu.push(suggestion);
                this.menu.push(competence);
                this.menu.push(budget);*/
                this.menu.push(dashboard);
                this.menu.push(medical);
                this.menu.push(suggestion);
                this.menu.push(competence);
                this.menu.push(budget);
                break;
            case 'analyst-marketing':
                this.menu.push(dashboard);
                this.menu.push(medical);
                this.menu.push(competence);
                this.menu.push(budget);

                this.menu.push(suggestion);
                // this.menu.push(product);
                // this.menu.push(line);
                // this.menu.push(user);
                break;
            case 'product-manager':
                this.menu.push(dashboard);
                this.menu.push(medical);

                this.menu.push(suggestion);
                this.menu.push(competence);
                this.menu.push(budget);
                // this.menu.push(product);
                // this.menu.push(line);
                // this.menu.push(user);
                break;
            case 'marketing-manager':
                this.menu.push(dashboard);
                this.menu.push(medical);
                this.menu.push(competence);
                this.menu.push(budget);

                this.menu.push(suggestion);
                // this.menu.push(product);
                // this.menu.push(line);
                // this.menu.push(user);
                break;
            case 'admin':
                this.menu.push(dashboard);
                this.menu.push(medical);
                this.menu.push(suggestion);
                this.menu.push(competence);
                this.menu.push(budget);
                this.menu.push(product);
                this.menu.push(line);
                this.menu.push(user);
                break;
            case 'admin-local':
                this.menu.push(dashboard);
                this.menu.push(medical);
                this.menu.push(suggestion);
                this.menu.push(competence);
                this.menu.push(budget);
                this.menu.push(product);
                this.menu.push(line);
                this.menu.push(user);
                break;
            case 'consult':
                this.menu.push(dashboard);
                this.menu.push(medical);
                this.menu.push(suggestion);
                this.menu.push(competence);
                this.menu.push(budget);
                // this.menu.push(product);
                // this.menu.push(line);
                // this.menu.push(user);
                break;
        }
    }

    ngAfterViewInit(): void {
        this.navActive();
    }

    handlerCollapse() {
        $('.navbar-nav .nav-item').click(function() {
            $('.navbar-nav .nav-item').removeClass('active');
            $(this).addClass('active');
        });
        $('.navbar-nav .nav-link').click(function() {
            $('.navbar-nav .nav-link').removeClass('text-white');
            $(this).addClass('text-white');
        });
    }

    actionCloseCollapse() {
        this.actionSideBar();
        $('#reports').collapse('hide');
        $('#help').collapse('hide');
        $('body').removeClass('nav-open');
        $('.hamburger').removeClass('open');
    }

    navActive() {
        if (this.router.url === '/calibration' || this.router.url === '/engines') {
            return this.handlerNavActive('.report-ts');
        }
        if (this.router.url === '/training' || this.router.url === '/faq') {
            return this.handlerNavActive('.help-ts');
        }
    }

    handlerNavActive(el) {
        $('.navbar-nav .nav-item').removeClass('active');
        $('.navbar-nav .nav-link').removeClass('text-white');
        $(el).addClass('active');
        $(el + ' .nav-link').addClass('text-white');

    }

    actionSideBar() {
        this.navBarShort = !this.navBarShort;
    }
}

export interface MenuItem {
    name: string;
    url: string;
    icon: string;
}
