<div class="body">
    <div class="header">
        <h1 class="header-title">
            <svg-icon src="assets/svg/suggestions.svg"></svg-icon>
            {{'sugerencias'|translate}}
        </h1>
        <a href="javascript:void(0)"
           class="btn btn-gray"
           (click)="getExcel()">
            <svg-icon src="assets/svg/download.svg"></svg-icon>
            <span>{{'exportarexcel'|translate}}</span>
        </a>
    </div>
    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
        <!--KEYWORD-->
        <div class="input-material" style="flex-grow: 9">
            <input id="search" [(ngModel)]="filter.keyword" class="form-control" type="text" required/>
            <label for="search">
                <svg-icon src="assets/svg/search.svg"></svg-icon>
                {{'busca'|translate}}
            </label>
        </div>

        <div class="divider d-none d-md-block">&nbsp;</div>
        <!--COUNTRY-->
        <div class="input-material flex-grow-1">
            <ng-multiselect-dropdown
                    [settings]="dropSettings"
                    [(ngModel)]="filterCountries"
                    [data]="countriesUser"
                    [placeholder]="'buscar' | translate"
                    (ngModelChange)="getLinesByCountries();"
            >
            </ng-multiselect-dropdown>
            <label class="mb-0 mr-3 disabled">{{'pais' | translate}}</label>
        </div>
        <!--LINE-->
        <div class="input-material flex-grow-1">
            <select [(ngModel)]="filter.line" id="product" class="form-control" name="type"
                    (ngModelChange)="getProductsByLine()"
            >
                <option selected [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                <option *ngFor="let x of lines" [ngValue]="x.id">{{x.name}}</option>
            </select>
            <label for="product" class="mb-0 mr-3">{{'linha'|translate}}</label>
        </div>
        <!--PRODUCTO-->
        <div class="input-material flex-grow-1">
            <select id="status" [(ngModel)]="filter.product" class="form-control" name="type">
                <option selected [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                <option *ngFor="let p of products" [value]="p.id">{{p.name}}</option>
            </select>
            <label for="status" class="mb-0 mr-3">{{'produto'|translate}}</label>
        </div>
        <!--Status-->
        <div class="input-material flex-grow-1">
            <select id="country" [(ngModel)]="filter.status" class="form-control" name="type">
                <option selected [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                <option value="pending">{{translate.instant('statusNames.pending')}}</option>
                <option value="answered">{{translate.instant('statusNames.answered')}}</option>
                <!--                <option value="pending">Pendente</option>-->
                <!--                <option value="revision">Revisão</option>-->
                <!--                <option value="approved">Aprovado</option>-->
                <!--                <option value="disapproved">Desaprovado</option>-->
            </select>
            <label for="country" class="mb-0 mr-3">Status</label>
        </div>
        <!--PERIOD-->
        <div class="input-material flex-grow-1">
            <input id="start" class="form-control" type="text"
                   [(ngModel)]="this.filterFront.start" mask="d0/M0/0000" [dropSpecialCharacters]="false" required/>
            <label for="start">{{'start' | translate}}</label>
        </div>

        <div class="input-material flex-grow-1">
            <input id="end" class="form-control" type="text"
                   [(ngModel)]="this.filterFront.end" mask="d0/M0/0000" [dropSpecialCharacters]="false" required/>
            <label for="end">{{'end' | translate}}</label>
        </div>

        <a href="javascript:void(0)"
           (click)="onFilter()"
           class="btn btn-gray flex-grow-1"
           type="button">
            <span>{{'filtrar'|translate}}</span>
        </a>
    </div>
    <!--GRID-->
    <div class="table-responsive">
        <table class="table">
            <thead>
            <tr>
                <th scope="col">{{'linha'|translate}}</th>
                <th scope="col">{{'produto'|translate}}</th>
                <th scope="col">{{'usuario'|translate}}</th>
                <th scope="col">{{'datacadastro'|translate}}</th>
                <th><p class="text-center m-0">{{'pais' | translate}}</p></th>
                <th><p class="text-center m-0">{{'like' | translate}}</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isNullOrUndefined(result)">
                <td colspan="6" class="text-center py-5">{{'nenhumresultado'| translate}}</td>
            </tr>
            <tr *ngFor="let x of result">
                <td>
                    <div class="d-flex align-items-center" style="gap: 18px">
                        <div style="width: 4px;
                        height: 42px;" [ngClass]="{
                                'bg-warning' : (x.suggestion.status === 'pending'),
                                'bg-primary' : (x.suggestion.status === 'answered')
                            }"></div>
                        {{x.suggestion.product?.productLine?.name}}
                    </div>
                </td>
                <td>
                    <div class="d-flex">
                        {{x.suggestion.product.name}}
                    </div>
                </td>
                <td>{{x.suggestion.createdBy?.name}}</td>
                <td>{{x.suggestion.createdAt | date: 'dd/MM/yyyy'}}</td>
                <td class="d-flex flex-column align-items-center justify-content-center">
                    <img src="{{x.suggestion.country?.flag}}" width="25">{{x.suggestion.country.name}}
                </td>
                <td>
                    <div class="d-flex justify-content-center align-items-center"
                         style="gap:5px;">
                        <div class="d-flex align-items-center" style="gap: 7px">
                            <svg-icon src="assets/svg/like.svg"></svg-icon>
                            {{x.likes}}
                        </div>
                        <div class="d-flex align-items-center" style="gap: 7px">
                            <svg-icon src="assets/svg/dislike.svg"></svg-icon>
                            {{x.dislikes}}
                        </div>
                    </div>
                </td>
                <td>{{getStatusNameByKey(x.suggestion.status)}}</td>
                <td>
                    <button class="mr-4" [routerLink]="'/suggestions/' + x.suggestion.id">
                        <svg-icon src="assets/svg/opened.svg" *ngIf="x.suggestion.status === 'pending'"></svg-icon>
                        <svg-icon src="assets/svg/external.svg" *ngIf="x.suggestion.status !== 'pending'"></svg-icon>
                    </button>
                    <button (click)="actionDelete(x.suggestion.id)" *ngIf=actionUserProfile()>
                        <svg-icon src="assets/svg/delete.svg"></svg-icon>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

