<div class="body">
    <div class="header">
        <h1 class="header-title ">
            <svg-icon class="mr-3" src="assets/svg/tiles.svg"></svg-icon>
            Dashboard
        </h1>
        <a href="javascript:void(0)"
           class="btn btn-gray"
           (click)="getExcel()">
            <svg-icon src="assets/svg/download.svg"></svg-icon>
            <span>{{'exportarexcel' | translate}}</span>
        </a>
    </div>
    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">

        <!--PRODUCTO-->
        <div class="input-material flex-grow-1">
            <input id="start" class="form-control" type="text"
                   [(ngModel)]="this.statisticsFilterFront.start" mask="d0/M0/0000" [dropSpecialCharacters]="false" required/>
            <label for="start">{{'start' | translate}}</label>
        </div>

        <div class="input-material flex-grow-1">
            <input id="end" class="form-control" type="text"
                   [(ngModel)]="this.statisticsFilterFront.end" mask="d0/M0/0000" [dropSpecialCharacters]="false" required/>
            <label for="end">{{'end' | translate}}</label>
        </div>

        <!--COUNTRY-->
        <div class="input-material flex-grow-1">
            <ng-multiselect-dropdown
                    [settings]="dropSettings"
                    [(ngModel)]="filterCountries"
                    [data]="countriesUser"
                    [placeholder]="'buscar' | translate"
                    (ngModelChange)="getLinesByCountries();"
            >
            </ng-multiselect-dropdown>
            <label class="mb-0 mr-3 disabled">{{'pais' | translate}}</label>
        </div>

        <!-- LINE -->
        <div class="input-material flex-grow-1">
            <select id="linear" class="form-control" name="type" [(ngModel)]="statisticsFilter.line"
                    (ngModelChange)="getProductsByLine()"
            >
                <option selected
                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                <option [value]="l.id" *ngFor="let l of lines">{{l.name}} </option>
            </select>
            <label for="linear" class="mb-0 mr-3">{{'linha'|translate}}</label>
        </div>

        <div class="input-material flex-grow-1">
            <select id="status" class="form-control" name="type" [(ngModel)]="statisticsFilter.product">
                <option selected
                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                <option [value]="p.id" *ngFor="let p of products">{{p.name}}</option>
            </select>
            <label for="status" class="mb-0 mr-3">{{'produto'|translate}}</label>
        </div>


        <a href="javascript:void(0)"
           class="btn btn-primary flex-grow-1" (click)="onFilter();"
           type="button">
            <span>{{'filtrar'| translate}}</span>
        </a>
    </div>

    <div class="row justify-content-around">
        <div class="col-md-2">
            <div class="card_dash">
                <svg-icon src="assets/svg/question.svg"></svg-icon>
                <span>{{'consultamedica' | translate}}</span>
                <b>{{!statistics.consult ? 0 : statistics.consult}}</b>
            </div>
        </div>
        <div class="col-md-2">
            <div class="card_dash">
                <svg-icon src="assets/svg/suggest.svg"></svg-icon>
                <span>{{'sugerencias' | translate}}</span>
                <b>{{!statistics.suggestion ? 0 : statistics.suggestion}}</b>
            </div>
        </div>
        <div class="col-md-2">
            <div class="card_dash">
                <svg-icon src="assets/svg/competence.svg"></svg-icon>
                <span>{{'menu.competence' | translate}}</span>
                <b>{{!statistics.competence ? 0 : statistics.competence}}</b>
            </div>
        </div>
        <div class="col-md-2">
            <div class="card_dash">
                <svg-icon src="assets/svg/product.svg"></svg-icon>
                <span>{{'menu.budget' | translate}}</span>
                <b>{{!statistics.budget ? 0 : statistics.budget}}</b>
            </div>
        </div>
    </div>

    <div class="my-4">
        <hr/>
    </div>

    <div class="card_bg">
        <h4>{{'topprodutos'|translate}}</h4>
        <div class="row">
            <div class="col-12 col-md-6 col-xl-6">
                <div class="card_graph">
                    <div class="top">
                        <b>{{'consultamedica' | translate}}</b>
                    </div>
                    <google-chart style="width: 100%;"
                                  [height]="chartDataBarPrimary.height"
                                  [type]="chartDataBarPrimary.type"
                                  [data]="chartDataBarPrimary.data"
                                  [options]="chartDataBarPrimary.options">
                    </google-chart>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-6">
                <div class="card_graph">
                    <div class="top">
                        <b>{{'sugerencias' | translate}}</b>
                    </div>
                    <google-chart style="width: 100%;"
                                  [height]="chartDataBarSecondary.height"
                                  [type]="chartDataBarSecondary.type"
                                  [data]="chartDataBarSecondary.data"
                                  [options]="chartDataBarSecondary.options">
                    </google-chart>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-6">
                <div class="card_graph">
                    <div class="top">
                        <b>{{'menu.competence' | translate}}</b>
                    </div>
                    <google-chart style="width: 100%;"
                                  [height]="chartDataBarTertiary.height"
                                  [type]="chartDataBarTertiary.type"
                                  [data]="chartDataBarTertiary.data"
                                  [options]="chartDataBarTertiary.options">
                    </google-chart>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-6">
                <div class="card_graph">
                    <div class="top">
                        <b>{{'menu.budget' | translate}}</b>
                    </div>
                    <google-chart style="width: 100%;"
                                  [height]="chartDataBarQuartenary.height"
                                  [type]="chartDataBarQuartenary.type"
                                  [data]="chartDataBarQuartenary.data"
                                  [options]="chartDataBarQuartenary.options">
                    </google-chart>
                </div>
            </div>
        </div>
    </div>


    <div class="row justify-content-around">
        <div class="col-md-6">
            <div class="card">
                <div class="top">
                    <svg-icon class="mr-3" src="assets/svg/question.svg"></svg-icon>
                    <b>{{'consultamedica' | translate}}</b>
                </div>
                <div class="content">
                    <div class="row align-items-center justify-content-around">
                        <div class="col-md-2 px-0 text-nowrap">
                            <b>Status</b>
                        </div>
                        <div class="col-md-4">
                            <div class="card_content primary">
                                <b>{{cardConsultDashboard.pending}}</b>
                                <span>{{'open' | translate}}</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card_content secondary">
                                <b>{{cardConsultDashboard.answered}}</b>
                                <span>{{'minhalista.concluido' | translate}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row align-items-center justify-content-around">
                        <div class="col-md-2 px-0 text-nowrap">
                            <b>Likes</b>
                        </div>
                        <div class="col-md-8">
                            <!--{{cardConsultDashboard.positive}} - {{cardConsultDashboard.negative}}-->
                            <google-chart style="width: 100%;"
                                          [height]="likesChartConsult.height"
                                          [type]="likesChartConsult.type"
                                          [data]="likesChartConsult.data"
                                          [options]="likesChartConsult.options">
                            </google-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="card">
                <div class="top">
                    <svg-icon class="mr-3" src="assets/svg/suggest.svg"></svg-icon>
                    <b>{{'sugerencias' | translate}}</b>
                </div>
                <div class="content">
                    <div class="row align-items-center justify-content-around">
                        <div class="col-md-2 px-0 text-nowrap">
                            <b>Status</b>
                        </div>
                        <div class="col-md-4">
                            <div class="card_content primary">
                                <b>{{cardSuggestionDashboard.pending}}</b>
                                <span>{{'open' | translate}}</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card_content secondary">
                                <b>{{cardSuggestionDashboard.answered}}</b>
                                <span>{{'minhalista.concluido' | translate}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="row align-items-center justify-content-around">
                        <div class="col-md-2 px-0 text-nowrap">
                            <b>Likes</b>
                        </div>
                        <div class="col-md-8">
                            <!--{{cardSuggestionDashboard.positive}} - {{cardSuggestionDashboard.negative}}-->
                            <google-chart style="width: 100%;"
                                          [height]="likesChartSuggestion.height"
                                          [type]="likesChartSuggestion.type"
                                          [data]="likesChartSuggestion.data"
                                          [options]="likesChartSuggestion.options">
                            </google-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="card">
                <div class="top">
                    <svg-icon class="mr-3" src="assets/svg/competence.svg"></svg-icon>
                    <b>{{'menu.competence' | translate}}</b>
                </div>
                <div class="content">
                    <div class="row align-items-center justify-content-around">
                        <div class="col-md-2 px-0 text-nowrap">
                            <b>Status</b>
                        </div>
                        <div class="col-md-4">
                            <div class="card_content primary">
                                <b>{{cardCompetenceDashboard.pending}}</b>
                                <span>{{'open' | translate}}</span>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card_content secondary">
                                <b>{{cardCompetenceDashboard.answered}}</b>
                                <span>{{'minhalista.concluido' | translate}}</span>
                            </div>
                        </div>
                    </div>
                    <!--<div class="row align-items-center justify-content-around">
                        <div class="col-md-2">
                            <b>Likes</b>
                        </div>
                        <div class="col-md-8">
                            <img src="assets/images/graph.png" alt="">
                        </div>
                    </div>-->
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="card">
                <div class="top">
                    <svg-icon class="mr-3" src="assets/svg/product.svg"></svg-icon>
                    <b>{{'menu.budget' | translate}}</b>
                </div>
                <div class="content">
                    <div class="row align-items-center justify-content-around">
                        <div class="col-md-2 px-0 text-nowrap">
                            <b>Status</b>
                        </div>
                        <div class="col-md-10 d-flex flex-wrap">
                            <div class="card_content flex-grow-1 mx-1 approved">
                                <b>{{cardBudgetDashboard.approved}}</b>
                                <span>{{'approved-status' | translate}}</span>
                            </div>
                            <div class="card_content flex-grow-1 mx-1 review">
                                <b>{{cardBudgetDashboard.pending}}</b>
                                <span>{{'pendente'| translate}}</span>
                            </div>
                            <div class="card_content flex-grow-1 mx-1 secondary">
                                <b>{{cardBudgetDashboard.revision}}</b>
                                <span>{{'inRevision-status'| translate}}</span>
                            </div>
                            <div class="card_content flex-grow-1 mx-1 disapproved">
                                <b>{{cardBudgetDashboard.disapproved}}</b>
                                <span> {{'disapproved-status' | translate}}</span>
                            </div>
                        </div>
                        <!--<div class="col-md-2">
                            <div class="card_content approved">
                                <b>{{cardBudgetDashboard.approved}}</b>
                                <span>Aprovado</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="card_content review">
                                <b>{{cardBudgetDashboard.pending}}</b>
                                <span>Pendente</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="card_content secondary">
                                <b>{{cardBudgetDashboard.revision}}</b>
                                <span>Em revisão</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="card_content disapproved">
                                <b>{{cardBudgetDashboard.disapproved}}</b>
                                <span>Reprovado</span>
                            </div>
                        </div>-->
                    </div>
                    <!--<div class="row align-items-center justify-content-around">
                        <div class="col-md-2">
                            <b>Likes</b>
                        </div>
                        <div class="col-md-8">
                            <img src="assets/images/graph.png" alt="">
                        </div>
                    </div>-->
                </div>
            </div>
        </div>
    </div>

</div>


