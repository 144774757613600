import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaginationInstance} from 'ngx-pagination';

@Component({
    selector: 'app-pagination-custom',
    templateUrl: './pagination-custom.component.html',
    styleUrls: ['./pagination-custom.component.scss']
})
export class PaginationCustomComponent implements OnInit {
    @Input() id!: string;
    @Input() maxSize: number | undefined;
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() pageBoundsCorrection: EventEmitter<number> = new EventEmitter<number>();

    public config: PaginationInstance = {
        id: this.id,
        itemsPerPage: 10,
        currentPage: 1
    };

    constructor() {
    }

    ngOnInit(): void {
    }

}
