<div class="body">
    <div class="header">
        <h1 class="header-title">
            <svg-icon src="assets/svg/product.svg"></svg-icon>
            {{'verbaevento'|translate}}
        </h1>
        <a href="javascript:void(0)"
           class="btn btn-gray"
           (click)="getExcel()">
            <svg-icon src="assets/svg/download.svg"></svg-icon>
            <span>{{'exportarexcel'|translate}}</span>
        </a>
    </div>
    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
        <div class="input-material" style="flex-grow: 9">
            <input id="search" [(ngModel)]="filter.keyword" class="form-control" type="text" required/>
            <label for="search">
                <svg-icon src="assets/svg/search.svg"></svg-icon>
                {{'busca'|translate}}
            </label>
        </div>

        <div class="divider d-none d-md-block">&nbsp;</div>

        <!-- COUNTRY -->
        <div class="input-material flex-grow-1">
            <ng-multiselect-dropdown
                    [settings]="dropSettings"
                    [(ngModel)]="selectedCountries"
                    [data]="countriesUser"
                    [placeholder]="'buscar' | translate"
                    (ngModelChange)="getUnitiesFilter()">
            </ng-multiselect-dropdown>
            <label class="mb-0 mr-3 disabled">{{'pais' | translate}}</label>
        </div>

        <div class="input-material flex-grow-1">
            <select id="linear" class="form-control" name="type" [(ngModel)]="filter.city">
                <option selected
                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                <option [value]="c.id" *ngFor="let c of cityByCountry">{{c.name}}</option>
            </select>
            <label for="linear" class="mb-0 mr-3">Cidade</label>
        </div>

        <div class="input-material flex-grow-1">
            <select id="product" class="form-control" name="type" [(ngModel)]="filter.line"
                    (ngModelChange)="getProductsByLine()"
            >
                <option selected
                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                <option [value]="l.id" *ngFor="let l of lines">{{l.name}}</option>
            </select>
            <label for="product" class="mb-0 mr-3">  {{'linha'|translate}}</label>
        </div>

        <div class="input-material flex-grow-1">
            <select id="status" class="form-control" name="type" [(ngModel)]="filter.product">
                <option selected
                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                <option [value]="p.id" *ngFor="let p of products">{{p.name}}</option>
            </select>
            <label for="status" class="mb-0 mr-3">{{'produto'|translate}}</label>
        </div>
    </div>
    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">

        <div class="input-material flex-grow-1">
            <select id="country" class="form-control" name="type" [(ngModel)]="filter.status">
                <option selected
                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                <option value="pending">Pendente</option>
                <option value="revision">Revisão</option>
                <option value="approved">Aprovado</option>
                <option value="disapproved">Desaprovado</option>
                <!--<option value="pending-product">Pendente em Marketing</option>-->
            </select>
            <label for="country" class="mb-0 mr-3">Status</label>
        </div>
        <!--        <div class="input-material flex-grow-1">-->
        <!--            <select id="unity" class="form-control" name="type" [(ngModel)]="filter.unity">-->
        <!--                <option selected-->
        <!--                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>-->
        <!--                <option [value]="u.id" *ngFor="let u of unities | async" [ngValue]="u.id">{{u.name}}</option>-->
        <!--            </select>-->
        <!--            <label for="unity" class="mb-0 mr-3">Unidade</label>-->
        <!--        </div>-->


        <div class="bd-1 mb-0  input-wrapper small">
            <span class="label">Data de:</span>
            <div class="input-wrapper__fields">
                <label>
                    <input type="radio" name="type" [value]="false" id="criação" [(ngModel)]="this.filter.byEvent"/>
                    <span>Criação</span>
                </label>
                <label>
                    <input type="radio" [value]="true" name="type" id="evento" [(ngModel)]="this.filter.byEvent"/>
                    <span>Evento</span>
                </label>
            </div>
        </div>

        <div class="input-material flex-grow-1">
            <input id="start" class="form-control" type="text"
                   [(ngModel)]="this.filterFront.start" mask="d0/M0/0000" [dropSpecialCharacters]="false" required/>
            <label for="start">{{'start' | translate}}</label>
        </div>

        <div class="input-material flex-grow-1">
            <input id="end" class="form-control" type="text"
                   [(ngModel)]="this.filterFront.end" mask="d0/M0/0000" [dropSpecialCharacters]="false" required/>
            <label for="end">{{'end' | translate}}</label>
        </div>


        <a href="javascript:void(0)"
           (click)="onFilter()"
           class="btn btn-gray flex-grow-1"
           type="button">
            <span>{{'filtrar'|translate}}</span>
        </a>
    </div>
    <div class="table-responsive">
        <table class="table">
            <thead>
            <tr>
                <th scope="col">{{'dataevento'|translate}}</th>
                <th scope="col">{{'usuario'|translate}}</th>
                <!--                <th scope="col">{{'Unidade'}}</th>-->
                <th scope="col">Local</th>
                <th><p class="text-center m-0">{{'pais' | translate}}</p></th>
                <th scope="col">Valor</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let b of result.results
                | paginate
                : {
                    id: 'budget',
                    itemsPerPage: this.result.pageSize,
                    currentPage: this.result.currentPage,
                    totalItems: this.result.totalCount
            }"
            >
                <td>
                    <div class="d-flex align-items-center" style="gap: 18px">
                        <div style="width: 4px;
                        height: 42px;" [ngClass]="{
                                'bg-warning' : (b.status === 'pending' || b.status === 'pending-product'),
                                'bg-info' : (b.status === 'revision'),
                                'bg-success' : (b.status === 'approved'),
                                'bg-danger' : (b.status === 'disapproved' || b.status === 'disapproved-product')
                            }"></div>
                        {{b.date | date: 'dd/MM/yyyy' : 'UTC'}}
                    </div>
                </td>
                <td>{{b.createdBy.name}}</td>
                <!--                <td>-->
                <!--                    {{b.unity.name}}-->
                <!--                </td>-->
                <td>{{b.city.name}}</td>
                <td class="d-flex flex-column align-items-center justify-content-center">
                    <img src="{{b.city.country?.flag}}" width="25">{{b.city.country?.name}}
                </td>
                <td>{{b.shareValue | currency : '$'}}</td>
                <td>{{getStatusNameByKey(b.status)}}</td>
                <td>
                    <button class="mr-4" [routerLink]="'/budget-event/' + b.id ">
                        <svg-icon
                                *ngIf="b.status === 'approved'||b.status === 'disapproved' || b.status === 'disapproved-product'"
                                src="assets/svg/link.svg"></svg-icon>
                        <svg-icon *ngIf="b.status === 'revision'" src="assets/svg/review.svg"></svg-icon>
                        <svg-icon *ngIf="b.status === 'pending' ||b.status === 'pending-product'"
                                  src="assets/svg/opened.svg"></svg-icon>
                    </button>
                    <button (click)="actionDelete(b.id)" *ngIf=actionUserProfile()>
                        <svg-icon src="assets/svg/delete.svg"></svg-icon>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
        <div class="text-right">
            <app-pagination-custom
                    [id]="'budget'"
                    (pageChange)="pageChanged($event)"
            ></app-pagination-custom>
        </div>
    </div>
</div>

