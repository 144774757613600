import {Component, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {
    GetProductLinesGQL,
    ProductLine,
    ProductLinesQuery,
    SearchProductAll,
    SearchProductsAllGQL,
    SearchProductsAllQuery,
    GetUserByProfileGQL,
    GetUserByProfileQuery,
    RemoveProductGQL,
    UpdateProductInput,
    CreateProductInput,
    CreateProductGQL,
    UpdateProductGQL,
    Product,
    ProductManager,
    User,
    RemoveProductManagerGQL,
    CreateProductManagerUser,
    GetProductByIdGQL,
    Country,
    GetCountriesQuery,
    GetCountriesGQL,
    CreateProductCountryInput,
    ProductPage,
    SearchProductsQuery,
    SearchProductsGQL,
    SearchProduct,
    GetCountriesByUserQuery,
    GetCountriesByUserGQL,
    GetCountriesByUserIdGQL,
    GetCountriesByUserIdQuery,
    CreateProductManagerCountry,
    CreateProductManagerInput,
    CreateProductManagerProduct,
    CreateProductManagerGQL, SearchProductLine, SearchProductLinesByCountryQuery, SearchProductLinesByCountryGQL,

} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {ListItem} from 'ng-multiselect-dropdown/multiselect.model';
import {UserService} from '../../service/user.service';
import {environment} from '../../../environments/environment';

declare var $: any;
declare var moment: any;

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent extends BaseComponent implements OnInit, OnDestroy {

    user = new User();
    // lineQuery: QueryRef<ProductLinesQuery>;
    // line: Array<ProductLine>;

    lines: Array<ProductLine> = [];
    linesQuery: QueryRef<SearchProductLinesByCountryQuery>;

    productQuery: QueryRef<SearchProductsQuery>;
    product: ProductPage = new ProductPage();

    byProfileQuery: QueryRef<GetUserByProfileQuery>;
    byProfile: Array<User>;

    model: Product = new Product();
    productLineSelected: string;

    filterL = new SearchProductLine();

    filter = new SearchProduct();
    manager: Array<ProductManager> = [];
    selectProfile: string;
    selectManager: any;

    // COUNTRY
    countries: Array<Country> = [];
    countryQuery: QueryRef<GetCountriesQuery>;

    filterCountries: Country[] = [];
    selectedCountries: Country[] = [];

    countriesUser: Array<Country> = [];
    countryUserQuery: QueryRef<GetCountriesByUserQuery>;

    countriesManager: Array<Country> = [];
    countryUserIDQuery: QueryRef<GetCountriesByUserIdQuery>;

    selectCountryCreateManager: any;

    dropSettings: any = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Marcar todos',
        unSelectAllText: 'Desmarcar todos',
        itemsShowLimit: 8,
        allowSearchFilter: true
    };

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                private productLines: GetProductLinesGQL,
                private searchProduct: SearchProductsGQL,
                private getUserByProfile: GetUserByProfileGQL,
                private removeProduct: RemoveProductGQL,
                public updateProduct: UpdateProductGQL,
                public createProduct: CreateProductGQL,
                public createProductManager: CreateProductManagerGQL,
                public removeProductManager: RemoveProductManagerGQL,
                public searchProductLinesByCountryGQL: SearchProductLinesByCountryGQL,
                public getProductById: GetProductByIdGQL,
                public getCountriesGQL: GetCountriesGQL,
                public getCountriesByUserGQL: GetCountriesByUserGQL,
                public getCountriesByUserIdGQL: GetCountriesByUserIdGQL,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, loading, translate);
    }

    ngOnInit() {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
            }, error: err => super.onError(err)
        });
        // this.filter = {
        //     start: moment().startOf('day').subtract(30, 'days').utcOffset(0, true).format(),
        //     end: moment().endOf('day').utcOffset(0, true).format(),
        //     countries: []
        // };
        // this.setDatePicker('js-daterangepicker', (start, end, label) => {
        //     this.filter.start = start.utcOffset(0, true).format();
        //     this.filter.end = end.utcOffset(0, true).format();
        // });
        // this.getProductLineName();
        // this.getCountries();
        this.getProductName();
        this.getCountriesByUser();

    }

    ngOnDestroy(): void {
        const elModalProd = $('#modalAddProduct');
        if (elModalProd[0]) {
            elModalProd.remove();
        }
    }

    showModal(idModal) {
        $('#' + idModal).modal('show');
    }

    getProductName() {
        this.filter.countries = this.filterCountries.map(e => e.id);
        this.productQuery = this.searchProduct.watch({filter: this.filter});
        this.productQuery.valueChanges.subscribe(({data}) => {
            this.product = data.searchProduct as ProductPage;
        });
    }

    getLinesByCountries() {
        this.filterL.countries = this.filterCountries.map(e => e.id);
        this.linesQuery = this.searchProductLinesByCountryGQL.watch({id: this.filterL.countries});
        this.linesQuery.valueChanges.subscribe(({data}) => {
            this.lines = data.searchProductLinesByCountry as Array<ProductLine>;
        });
    }

    // getProductLineName() {
    //     this.lineQuery = this.productLines.watch();
    //     this.lineQuery.valueChanges.subscribe(({data}) => {
    //         this.line = data.productLines as Array<ProductLine>;
    //     });
    // }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            this.countries = data.countries as Array<Country>;
        });
    }

    getCountriesByUser() {
        this.countryUserQuery = this.getCountriesByUserGQL.watch();
        this.countryUserQuery.valueChanges.subscribe(({data}) => {
            this.countriesUser = data.countriesByUser as Array<Country>;
        });
    }

    countryByManagerUser(idManager) {
        this.countryUserIDQuery = this.getCountriesByUserIdGQL.watch({id: idManager});
        this.countryUserIDQuery.valueChanges.subscribe(({data}) => {
            this.countriesManager = data.countriesByUser as Array<Country>;
        });
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: `${this.translate.instant('nome')}<br>`},
            {value: this.productLineSelected, text: `${this.translate.instant('linha')}<br>`},
            {value: this.selectedCountries, text: `${this.translate.instant('pais')}<br>`},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }
        if (super.isNullOrUndefined(this.model.id)) {
            this.actionCreatedProduct();
        } else {
            this.actionUpdateProduct();
        }
    }

    actionEdit(item: Product) {
        console.log(item);
        this.selectProfile = null;
        this.selectManager = null;

        Object.assign(this.model, item);
        this.productLineSelected = item.productLine.id;
        this.selectedCountries = item.countries;
        this.showModal('modalAddProduct');

    }

    ShowModalEdit(item: Product) {

        this.selectProfile = null;
        this.selectManager = null;

        Object.assign(this.model, item);
        this.productLineSelected = item.productLine.id;
        this.selectedCountries = item.countries;
        this.showModal('modalUserManag');

    }

    actionCreatedProduct() {
        const data: CreateProductInput = {
            name: this.model.name,
            productLine: {
                id: this.productLineSelected,
            },
            countries: this.selectedCountries.map(e => {
                const c = new CreateProductCountryInput();
                c.id = e.id;
                return c;
            })
        };
        this.createProduct.mutate({input: data}).subscribe((result) => {
            this.model = result.data.createProduct as Product;
            this.productQuery.refetch();
            super.showMessage(this.translate.instant('baseComponent.success'),
                this.translate.instant('baseComponent.warningProductSuccess'), 'success');
        });
    }

    actionUpdateProduct() {
        const data: UpdateProductInput = {
            id: this.model.id,
            name: this.model.name,
            productLine: {
                id: this.productLineSelected,
            },
            countries: this.selectedCountries.map(e => {
                const c = new CreateProductCountryInput();
                c.id = e.id;
                return c;
            })
        };
        this.updateProduct.mutate({input: data}).subscribe(() => {
            this.productQuery.refetch();
            this.closeModal('modalAddProduct');
            super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningProductEditSuccess'), 'success');
        });
    }

    addManager() {
        const arrValidateFields = [
            {value: this.selectProfile, text: 'Gestão<br>'},
            {value: this.selectManager, text: 'Usuários<br>'}
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningCorrectFields')`<br> ${stringError}`);
            return;
        }

        const data = new CreateProductManagerInput();
        data.product = new CreateProductManagerProduct();
        data.product.id = this.model.id;
        data.user = new CreateProductManagerUser();
        data.user.id = this.selectManager.id;
        data.type = this.selectProfile;
        data.country = new CreateProductManagerCountry();
        data.country.id = this.selectCountryCreateManager;
        this.createProductManager.mutate({input: data}).subscribe((result) => {
            this.model.managers = Object.assign([], this.model.managers);
            this.model.managers.push(result.data.createProductManager as ProductManager);
        });
    }

    onFilter() {
        this.filter.countries = this.filterCountries.map(e => e.id);
        this.productQuery.refetch({filter: this.filter});
    }

    actionNew() {
        this.model = new Product();
        this.productLineSelected = null;
        this.selectedCountries = [];
        this.showModal('modalAddProduct');
    }

    actionDelete(idProduct) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningProductDelete'), () => {
            this.removeProduct.mutate({id: idProduct}).subscribe((result) => {
                this.productQuery.refetch();
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningProductDeleteSuccess'), 'success');
            });
        });
    }

    getType(managers: any, type: string) {
        const a = managers.filter((m) => {
                return m.type === type;
            }
        );
        return a;
    }

    changeProfile() {
        if (super.isNullOrUndefined(this.selectProfile)) {
            this.byProfile = [];
            return;
        }
        this.byProfile = new Array<User>();
        this.byProfileQuery = this.getUserByProfile.watch({profile: this.selectProfile});
        this.byProfileQuery.valueChanges.subscribe(({data}) => {
            this.byProfile = data.findUsersByProfile as Array<User>;
            this.selectManager = undefined;
        });
    }

    removeManagers(idManager: string) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningManagerDelete'), () => {
            this.removeProductManager.mutate({id: idManager}).subscribe((result) => {
                this.model.managers = Object.assign([], this.model.managers);
                const index = this.model.managers.findIndex(e => e.id === idManager);
                this.model.managers.splice(index, 1);
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningManagerDeleteSuccess'), 'success');
            });
        });
    }

    getProduct() {
        this.getProductById.fetch({id: this.model.id}).subscribe((result) => {
            Object.assign(this.model, result.data.product);
        });
    }

    onSelectDrop(item: any): void {
        /* const o = this.selectedOperations.find(sp => sp.id === item.id);
        this.setSelectedOperation(o); */
    }

    onDeselect(item: any): void {
    }

    actionUserProfile() {
        switch (this.user.profile) {
            case 'medical-manager':
                return false;
            case 'district-manager':
                return false;
            case 'analyst-marketing':
                return false;
            case 'product-manager':
                return false;
            case 'marketing-manager':
                return false;
            case 'admin':
                return true;
            case 'admin-local':
                return true;
            case 'consult':
                return false;
        }
    }

    validateAdd() {
        switch (this.user.profile) {
            case 'medical-manager':
                return false;
            case 'district-manager':
                return false;
            case 'analyst-marketing':
                return false;
            case 'product-manager':
                return false;
            case 'marketing-manager':
                return false;
            case 'admin':
                return true;
            case 'admin-local':
                return true;
            case 'consult':
                return false;
        }
    }

    pageChanged(event) {
        this.filter.page = event;
        this.onFilter();
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        let url = `${environment.base_url}/products/search-product/excel?token=${token}`;
        url += ('&culture=' + this.translate.currentLang);
        if (!super.isNullOrUndefined(this.filter.keyword)) {
            url += ('&keyword=' + this.filter.keyword);
        }
        if (!super.isNullOrUndefined(this.filterCountries)) {
            url += ('&countries=');
            this.filterCountries.forEach((e, index) => {
                url += ((index !== 0 ? '|' : '') + e.id);
            });
        }
        if (!super.isNullOrUndefined(this.filter.line)) {
            url += ('&line=' + this.filter.line);
        }
        console.log(url);
        window.open(encodeURI(url));
    }


    protected readonly event = event;

    imporExcel(event: any) {
        const files = event.target.files;
        this.userService.uploadImport('products', files[0]).subscribe((data) => {
            this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('importSuccess'), 'success');
        }, error => super.onErrorRest(error));
    }
}

