<div class="single__page">
  <h1 class="mb-5">
    <svg-icon src="assets/svg/logo-eurofarma.svg"></svg-icon>
  </h1>
  <p>
   {{'naologado'|translate}}
  </p>
  <p>
    <a href="javascript:void('Login');" [routerLink]="['/authenticate']">   {{'cliquieaquipara'|translate}}<strong> {{'entrar'|translate}}</strong></a>
  </p>
</div>
