<div class="body">
    <div class="header">
        <h1 class="header-title">
            <svg-icon src="assets/svg/productblue.svg"></svg-icon>
            {{'linha'|translate}}
        </h1>
        <div class="d-flex  justify-content-between">
            <a href="javascript:void(0)"
               (click)="actionNew()"  *ngIf=actionUserProfile()
               class="btn btn-primary"
               type="button">
                <svg-icon src="assets/svg/add.svg"></svg-icon>
                <span class="ml-1">{{'adicionar'|translate}}</span>
            </a>
        </div>

    </div>
    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
        <div class="input-material" style="flex-grow: 9">
            <input [(ngModel)]="filter.keyword" id="search" maxlength="100" class="form-control" type="text" required/>
            <label for="search">
                <svg-icon src="assets/svg/search.svg"></svg-icon>
                {{'busca'|translate}}
            </label>
        </div>

        <div class="divider d-none d-md-block">&nbsp;</div>

        <!--COUNTRY-->
        <div class="input-material flex-grow-1">
            <ng-multiselect-dropdown
                    [settings]="dropSettings"
                    [(ngModel)]="filterCountries"
                    [data]="countriesUser"
                    [placeholder]="'buscar' | translate"
            >
            </ng-multiselect-dropdown>
            <label class="mb-0 mr-3 disabled">{{'pais' | translate}}</label>
        </div>

<!--        <div class="input-material" style="flex-grow:3">-->
<!--            <input id="start" class="form-control js-daterangepicker" type="text"-->
<!--                   required/>-->
<!--            <label for="start">{{'periodo'|translate}}</label>-->
<!--        </div>-->

        <a href="javascript:void(0)" (click)="onFilter()"
           class="btn btn-gray flex-grow-1"
           type="button">
            <span>{{'filtrar'|translate}}</span>
        </a>
    </div>
    <div class="table-responsive">
        <table class="table">
            <thead>
            <tr>
                <th scope="col">{{'nome'|translate}}</th>
                <th scope="col">{{'pais'|translate}}</th>
                <th colspan="5" scope="col" class="w-10"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let l of lines.results | paginate
                      : {
                          id: 'line',
                          itemsPerPage: this.lines.pageSize,
                          currentPage: this.lines.currentPage,
                          totalItems: this.lines.totalCount
                        } ">
                <!--                <td>-->
                <!--                    <div class="d-flex align-items-center" style="gap: 18px">-->
                <!--                        0215-->
                <!--                    </div>-->
                <!--                </td>-->
                <td>{{l.name}}</td>
                <td><span *ngFor="let c of l.countries; last as isLast"> {{c.name}} {{isLast ? '' : '|'}} </span></td>
                <td>
                    <button (click)="actionEdit(l)"  *ngIf=actionUserProfile()>
                        <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                    </button>
                    <button (click)="actionDelete(l.id)" *ngIf=actionUserProfile()>
                        <svg-icon src="assets/svg/delete.svg"></svg-icon>
                    </button>
                </td>
            </tr>
            <tr *ngIf="isNullOrUndefined(lines)">
                <td colspan="2" class="text-center py-4">{{'nenhumresultado'|translate}}</td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="text-right">
        <app-pagination-custom
                [id]="'line'"
                (pageChange)="pageChanged($event)"
        ></app-pagination-custom>
    </div>

    <div class="modal-custom modal fade" id="modalAddLine" data-keyboard="false"
         data-backdrop="static" role="dialog"
         aria-labelledby="modalNoteResumeLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">

            <div class="modal-content">
                <div class="modal_header">
                    <h3 class="header-title">
                        {{isNullOrUndefined(this.model.id) ? 'Nova Linha' : 'Editar Linha'}}
                    </h3>

                    <a href="javascript:$('#modalAddLine').modal('hide'); void('');"
                       class="btn"
                       type="button">
                        <svg-icon src="assets/svg/dismiss.svg"></svg-icon>
                    </a>

                </div>
                <div class="modal-body modal-body--small">
                    <!--NAME-->
                    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
                        <div class="input-material" style="flex-grow:3">
                            <input id="start1" [(ngModel)]="model.name" maxlength="100" class="form-control" type="text"
                                   required/>
                            <label for="start1">{{'nome'|translate}}</label>
                        </div>
                    </div>
                    <!-- COUNTRY -->
                    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
                        <div class="input-material flex-grow-1">
                            <ng-multiselect-dropdown
                                    [settings]="dropSettings"
                                    [(ngModel)]="selectedCountries"
                                    [data]="countriesUser"
                                    [placeholder]="'buscar' | translate"
                                    (onSelect)="onSelectDrop($event)"
                                    (onDeSelect)="onDeselect($event)">
                            </ng-multiselect-dropdown>
                            <label class="mb-0 mr-3 disabled">{{'pais' | translate}}</label>
                        </div>
                    </div>


                    <button class="btn btn-block btn-warning mt-3"
                            (click)="actionSave()" *ngIf=actionUserProfile()>{{'salvar'|translate}}</button>
                </div>

            </div>
        </div>
    </div>
</div>

