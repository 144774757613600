<div class="body">
    <div class="header flex-wrap">
        <h1 class="header-title">
            <svg-icon src="assets/svg/userblue.svg"></svg-icon>
            {{'usuarios'|translate}}
        </h1>

        <div class="button_section">
            <a class="btn btn-gray" type="button" href="assets/import/model_import_user_GFAM.xlsx">
                <svg-icon src="assets/svg/excel.svg"></svg-icon>
                <span class="ml-1"> {{'model'|translate}}</span>
            </a>

            <label for="import" class="btn btn-gray mb-0">
                <svg-icon src="assets/svg/upload.svg"></svg-icon>
                <span class="ml-1"> {{'importar'|translate}}</span>
                <input class="d-none" type="file" id="import" name="import" (change)="imporExcel($event)"
                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
            </label>

            <a href="javascript:void(0)" class="btn btn-gray" type="button" (click)="getExcel()">
                <svg-icon src="assets/svg/download.svg"></svg-icon>
                <span class="ml-1">{{'exportar'|translate}}</span>
            </a>

            <a href="javascript:void(0)" (click)="actionNew()" *ngIf=actionUserProfile()
               class="btn btn-primary"
               type="button">
                <svg-icon src="assets/svg/add.svg"></svg-icon>
                <span class="ml-1">{{'adicionar'|translate}}</span>
            </a>
        </div>

    </div>
    <div class="d-flex align-items-center flex-wrap mb-5" style="gap: 17px">
        <!-- KEYWORD -->
        <div class="input-material" style="flex-grow: 9">
            <input id="search" class="form-control" type="text" [(ngModel)]="filter.keyword"/>
            <label for="search">
                <svg-icon src="assets/svg/search.svg"></svg-icon>
                {{'busca'|translate}}
            </label>
        </div>


        <div class="divider d-none d-md-block">&nbsp;</div>

        <!--        <div class="input-material flex-grow-1">-->
        <!--            <select id="product" class="form-control" name="type">-->
        <!--                <option selected-->
        <!--                        [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>-->
        <!--                <option>text</option>-->
        <!--                <option>text</option>-->
        <!--                <option>text</option>-->
        <!--            </select>-->
        <!--            <label for="product" class="mb-0 mr-3">Linea</label>-->
        <!--        </div>-->

        <!-- COUNTRY DROPDOWN -->

        <div class="input-material flex-grow-1">
            <ng-multiselect-dropdown
                    [settings]="dropSettings"
                    [(ngModel)]="filterCountries"
                    [data]="countriesUser"
                    [placeholder]="'buscar' | translate"
            >
            </ng-multiselect-dropdown>
            <label class="mb-0 mr-3 disabled">{{'pais' | translate}}</label>
        </div>

        <!-- PROFILE DROPDOWN -->
        <div class="input-material flex-grow-1">
            <select id="profile" class="form-control" name="type" [(ngModel)]="filter.profile">
                <option selected
                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>
                <option [ngValue]="'admin'">{{'managersName.admin'| translate}}</option>
                <option [ngValue]="'admin-local'">{{'managersName.admin-local'| translate}}</option>
                <option [ngValue]="'analyst-marketing'">{{'managersName.analyst-marketing'| translate}}</option>
                <option [ngValue]="'consult'">{{'managersName.consult' | translate}}</option>
                <option [ngValue]="'district-manager'">{{'managersName.district-manager'| translate}}</option>
                <option [ngValue]="'marketing-manager'">{{'managersName.marketing-manager'| translate}}</option>
                <option [ngValue]="'product-manager'">{{'managersName.product-manager'| translate}}</option>
                <option [ngValue]="'medical-manager'">{{'managersName.medical-manager' | translate}}</option>
                <option [ngValue]="'user'">{{'managersName.user'| translate}}</option>
            </select>
            <label for="profile" class="mb-0 mr-3">{{'perfil'|translate}}</label>
        </div>
        <!--        &lt;!&ndash; UNITY DROPDOWN &ndash;&gt;-->
        <!--        <div class="input-material flex-grow-1">-->
        <!--            <select id="unity" class="form-control" name="type" [(ngModel)]="filter.unity">-->
        <!--                <option selected-->
        <!--                        [ngValue]="undefined">{{'minhalista.input3.subtitle' | translate}}</option>-->
        <!--                <option *ngFor="let u of unities | async" [ngValue]="u.id">{{u.name}}</option>-->

        <!--            </select>-->
        <!--            <label for="unity" class="mb-0 mr-3">{{'unidade'|translate}}</label>-->
        <!--        </div>-->

        <a href="javascript:void(0)"
           class="btn btn-gray flex-grow-1"
           type="button" (click)="onFilter()">
            <span>{{'filtrar'|translate}}</span>
        </a>
    </div>

    <!--GRID-->
    <div class="table-responsive">
        <table class="table">
            <thead>
            <tr>
                <!--                <th scope="col">ID</th>-->
                <th scope="col">{{'usuarios'|translate}}</th>
                <th scope="col">Status</th>
                <th scope="col">{{'perfil'|translate}}</th>
                <th scope="col">E-mail</th>
                <!--                <th scope="col">{{'unidade'|translate}}</th>-->
                <th class="text-center" scope="col">{{'pais'|translate}}</th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="users.results.length === 0">
                <td colspan="7">
                    <p class="text-center py-5">{{'nenhumresultado'|translate}}</p>
                </td>
            </tr>
            <tr *ngFor="let x of users.results
                    | paginate
                      : {
                          id: 'user',
                          itemsPerPage: this.users.pageSize,
                          currentPage: this.users.currentPage,
                          totalItems: this.users.totalCount
                        }">
                <!--                <td>-->
                <!--                    <div class="d-flex align-items-center" style="gap: 18px">-->
                <!--                        0215-->
                <!--                    </div>-->
                <!--                </td>-->
                <td>{{x.name}}</td>
                <td>{{!x.status ? '' : getStatusNameByKey(x.status)}}</td>
                <td>{{getMangerTypeByKey(x.profile)}}</td>
                <td>{{x.email}}</td>
                <td class="text-center">
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="position-relative" style="left: 15px;">
                            <img class="user-photo border-warning position-relative mr-0"
                                 *ngFor="let p of x.countries.slice(0, 5); let i = index"
                                 [ngStyle]="{'left': getPosition(i)}"
                                 src="{{p.flag}}"
                                 alt="img">
                        </div>
                        <span *ngIf="x.countries?.length > 5" class="position-relative"
                              style="right: 15px;">+{{ (x.countries?.length) - 5}}
                        </span>
                    </div>
                </td>
                <td>
                    <button (click)="actionEdit(x)" *ngIf=actionUserProfile()>
                        <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                    </button>
                    <button (click)="actionDelete(x.id)" *ngIf=actionUserProfile()>
                        <svg-icon src="assets/svg/delete.svg"></svg-icon>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <div class="text-right">
        <app-pagination-custom
                [id]="'user'"
                (pageChange)="pageChanged($event)"
        ></app-pagination-custom>
    </div>

    <!--MODAL USER-->
    <div class="modal-custom modal fade" id="modalUser" data-keyboard="false"
         data-backdrop="static" role="dialog"
         aria-labelledby="modalNoteResumeLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal_header">
                    <h3 class="header-title">
                        {{'usuarios'|translate}}
                    </h3>
                    <a href="javascript:void('');"
                       class="btn "
                       type="button" (click)="closeModal('modalUser')">
                        <svg-icon src="assets/svg/dismiss.svg"></svg-icon>
                    </a>
                </div>
                <div class="modal-body modal-body--small">
                    <div class="row my-3">
                        <!--NAME-->
                        <div class="col-4">
                            <div class="input-material">
                                <input id="nameUser" class="form-control" type="text"
                                       required [(ngModel)]="model.name" maxlength="150"/>
                                <label for="nameUser">  {{'nome'|translate}}</label>
                            </div>
                        </div>
                        <!--RE-->
                        <div class="col-4">
                            <div class="input-material">
                                <input id="reUser" class="form-control" type="text"
                                       required [(ngModel)]="model.re" maxlength="15"/>
                                <label for="reUser">RE</label>
                            </div>
                        </div>
                        <!-- PROFILE DROPDOWN -->
                        <div class="col-4">
                            <div class="input-material flex-grow-1">
                                <select id="profileUser" class="form-control" name="type" [(ngModel)]="model.profile">
                                    <option selected
                                            [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>
                                    <option [ngValue]="'admin'">{{'managersName.admin'| translate}}</option>
                                    <option [ngValue]="'admin-local'">{{'managersName.admin-local'| translate}}</option>
                                    <option [ngValue]="'analyst-marketing'">{{'managersName.analyst-marketing'| translate}}</option>
                                    <option [ngValue]="'consult'">{{'managersName.consult' | translate}}</option>
                                    <option [ngValue]="'district-manager'">{{'managersName.district-manager'| translate}}</option>
                                    <option [ngValue]="'marketing-manager'">{{'managersName.marketing-manager'| translate}}</option>
                                    <option [ngValue]="'product-manager'">{{'managersName.product-manager'| translate}}</option>
                                    <option [ngValue]="'medical-manager'">{{'managersName.medical-manager' | translate}}</option>
                                    <option [ngValue]="'user'">{{'managersName.user'| translate}}</option>
                                </select>
                                <label for="profileUser" class="mb-0 mr-3">  {{'perfil'|translate}}</label>
                            </div>
                        </div>
                        <!--EMAIL-->
                        <div class="col-6 mt-3">
                            <div class="input-material">
                                <input id="userEmail" class="form-control" type="text"
                                       required [(ngModel)]="model.email" maxlength="150"/>
                                <label for="userEmail">E-mail</label>
                            </div>
                        </div>
                        <!-- COUNTRY DROPDOWN -->
                        <div class="col-6 mt-3">

                            <div class="input-material flex-grow-1">
                                <ng-multiselect-dropdown
                                        [settings]="dropSettings"
                                        [(ngModel)]="userCountries"
                                        [data]="countriesUser"
                                        [placeholder]="'buscar' | translate"
                                >
                                </ng-multiselect-dropdown>
                                <label class="mb-0 mr-3 disabled">{{'pais' | translate}}</label>
                            </div>
                        </div>
                        <!-- UNITY DROPDOWN -->
                        <!--                        <div class="col-4 mt-3">-->
                        <!--                            <div class="input-material flex-grow-1">-->
                        <!--                                <select id="unityUser" class="form-control" name="type" [(ngModel)]="userUnity">-->
                        <!--                                    <option selected-->
                        <!--                                            [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>-->
                        <!--                                    <option *ngFor="let u of unities | async" [ngValue]="u.id">{{u.name}}</option>-->

                        <!--                                </select>-->
                        <!--                                <label for="unityUser" class="mb-0 mr-3">  {{'unidade'|translate}}</label>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                    </div>
                    <button class="btn btn-block btn-warning mt-3" (click)="actionSave()"
                            *ngIf=actionUserProfile()>  {{'salvar'|translate}}</button>
                </div>

            </div>
        </div>
    </div>


</div>

