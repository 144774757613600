import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {Apollo, QueryRef} from 'apollo-angular';
import {
    ChangeNotificationViewGQL,
    GetNotificationGQL,
    GetNotificationQuery,
    ProductLine,
    ProductLinesQuery, User,
    UserNotification, UserNotificationsObject
} from '../../../generated/graphql';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends BaseComponent implements OnInit {

    notificationsQuery: QueryRef<GetNotificationQuery>;
    notifications: UserNotificationsObject;

    countNewReads = 0;

    constructor(public router: Router,
                public loading: NgxSpinnerService,
                public translate: TranslateService,
                public getNotifications: GetNotificationGQL,
                public changeNotificationView: ChangeNotificationViewGQL
    ) {
        super(router, loading, translate);
    }

    ngOnInit() {
        this.handlerNotifications();
    }


    actionNotification(model) {
        this.changeNotificationView.mutate({
            id: model.id,
            status: true
        }).subscribe((result) => {
            let url = '';
            switch (model.type) {
                case 'suggestion':
                    url = `/suggestions/${model.relationId}`;
                    break;
                case 'consult':
                    url = `/medical-appointment/${model.relationId}`;
                    break;
                case 'budget':
                    url = `/budget-event/${model.relationId}`;
                    break;
                case 'competence':
                    url = `/competence/${model.relationId}`;
                    break;
            }
            this.router.navigate([url]);
        });
    }

    handlerNotifications() {
        this.notificationsQuery = this.getNotifications.watch({});
        this.notificationsQuery.valueChanges.subscribe(({data}) => {
            this.notifications = data.userNotifications as UserNotificationsObject;
            setTimeout(() => {
                this.handlerNotifications();
            }, 5000);
        });
    }
}
